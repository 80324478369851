<template>
  <b-container class="h-75">
    <div class="logout text-center mt-5">
      <h1>Thank you for visiting us!</h1>
      <p>You are now logged out of CitSci</p>
      <b-button class="mt-5"
                variant="primary"
                alt="Home Page"
                title="Home Page">
        <router-link :to="{name: 'HomePage'}" replace style="text-decoration: none; color: inherit;">To Home Page</router-link>
      </b-button>
      <b-button class="mt-5"
                variant="tertiary"
                alt="Login"
                title="Login">
        <router-link :to="{name: 'Login'}" replace style="text-decoration: none; color: inherit;">To Login</router-link>
      </b-button>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {}
  },
  computer: {
    ...mapGetters({
      hasSession: "user/profile/hasSession",
    })
  },
  methods: {
    ...mapActions({
      logout: "user/auth/logout",
      clearUserData: "user/profile/reset",
    }),
  },
  created() {
    this.clearUserData();
    this.logout();
    if (this.$route.query.redirect) {
      // accept invite / other redirects if present
      this.$router.push({path: this.$route.query.redirect});
    }
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
</style>
