<template>
  <div>
    <div class="card mb-3" v-if="localReply && !isLoadingDelete"
         v-bind:class="localReply && highlight ? 'border-dark' : ''">
      <div class="card-body">
        <div class='row no-gutters bg-light border-bottom' v-if="localReply && localReply.files.length !== 0">
          <div class='col-lg-2 col-md-3 col-sm-4' v-for="(postFile, postFileIndex) in localReply.files">
            <img :src='postFile.path' class="img-fluid" alt="forum post image">
          </div>
        </div>
        <div class="initial-post">
          <div v-html="purify(localReply && localReply.discussionText)"></div>
          <div class="text-muted small text-center align-self-center float-right"
               v-if="canDelete">
            <b-button size="sm" title="Delete this post" variant="danger" @click="deleteReplyModal">
              <i class="fa fa-trash fa-1x"></i>
            </b-button>
          </div>
          <div class="small">
            <b>{{ localReply && localReply.user.displayName }}</b> posted on
            <b>{{ displayDate(localReply && localReply.updatedAt) }}</b>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Deleting forum post...
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import DOMPurify from 'dompurify';
import RequiresMember from "../../tabpermission/requiresMember.vue";

export default {
  name: "reply",
  components: {
    RequiresMember,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    highlight: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      localReply: this.post,
      deleteModalId: 'delete-forum-post-' + this.post.id,
      isLoadingDelete: false,
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isManager: "project/get/isManager",
      membershipMap: "user/profile/membershipMap",
    }),
  },
  methods: {
    ...mapActions({
      deletePost: "project/forum/delete",
    }),
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
    canDelete() {
      if (this.isManager) return true
      if (this.isMember) {
        return this.membershipMap[this.projectId] && this.membershipMap[this.projectId].userId === this.localParent.userId
      }
      return false
    },
    deleteReplyModal() {
      this.$bvModal.msgBoxConfirm(
        'Do you want to delete this Forum Post?\n' +
        'This cannot be undone.', {
          title: 'Delete Forum Post',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Yes, Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.isLoadingDelete = true
            this.deletePost({id: this.localReply.id}).then(val => {
              if (val) {
                // Pass deleted reply post id to parent
                this.$emit('deleted-reply', this.localReply.id)
                this.isLoadingDelete = false
              }
            })
          }
        })
        .catch(err => {
          console.log('error: ', err)
        })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
