import Vue from 'vue'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const update = ({ commit, dispatch }, jwtToken) => {
  commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, true)

  let payload = null

  try {
    payload = Vue.$jwt.decode(jwtToken)
  } catch (e) {
    commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
    commit(types.ACTIVATE_ACCOUNT_SET_ERROR, 'Invalid JWT token.')
    return
  }

  if (!payload || !payload.code) {
    commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
    commit(types.ACTIVATE_ACCOUNT_SET_ERROR, 'Invalid JWT token.')
    return
  }

  const page = '/users/'+payload.code+'/activate'

  return fetch(page, { method: 'PATCH', body: JSON.stringify({ 'token': jwtToken }) })
    .then(response => {})
    .then(() => {
      commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
      commit(types.ACTIVATE_ACCOUNT_SET_ACTIVATED, true)
      dispatch('user/profile/fetchSession', null, {root:true}).then(()=>{
        return
      })

    })
    .catch((e) => {
      commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
      commit(types.ACTIVATE_ACCOUNT_SET_ERROR, e.message)
    })
}

export const checkToken = ({ commit }, jwtToken) => {
  commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, true)

  if (jwtToken === 'undefined' || !jwtToken) {
    commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
    commit(types.ACTIVATE_ACCOUNT_SET_INVALID_TOKEN, true)
    return
  }

  let payload = null

  try {
    payload = Vue.$jwt.decode(jwtToken)
    if (!payload || payload.subject !== 'activate account') {
      commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
      commit(types.ACTIVATE_ACCOUNT_SET_INVALID_TOKEN, true)
      return
    }
  } catch (e) {
    commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
    commit(types.ACTIVATE_ACCOUNT_SET_INVALID_TOKEN, true)
    return
  }

  const currentTime = new Date().getTime() / 1000
  if (currentTime > payload.exp) {
    commit(types.ACTIVATE_ACCOUNT_SET_EXPIRED_TOKEN, true)
  }

  commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
}

export const reset = ({ commit }) => {
  commit(types.ACTIVATE_ACCOUNT_RESET)
}


export const resendEmail = ({ commit }, data) => {
  commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, true)
  console.log('resend verification email')
  const page = '/users/' + data.id + '/resend_activation'
  return fetch(page, { method: 'PATCH', body: JSON.stringify({ 'resendVerificationEmail': true }) })
    .then(response => {})
    .then(() => {
      commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
      commit(types.ACTIVATE_ACCOUNT_SET_EMAIL, true)
    })
    .catch((e) => {
      commit(types.ACTIVATE_ACCOUNT_TOGGLE_LOADING, false)
      commit(types.ACTIVATE_ACCOUNT_SET_ERROR, e.message)
    })
}
