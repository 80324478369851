import Vue from 'vue'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const update = ({ commit }, {item, resetToken}) => {
  commit(types.RESET_PASSWORD_TOGGLE_LOADING)

  let payload = null

  try {
    payload = Vue.$jwt.decode(resetToken)
  } catch (e) {
    commit(types.RESET_PASSWORD_TOGGLE_LOADING)
    commit(types.RESET_PASSWORD_SET_ERROR, 'Invalid JWT token.')
    return
  }

  if (!payload || !payload.code) {
    commit(types.RESET_PASSWORD_TOGGLE_LOADING)
    commit(types.RESET_PASSWORD_SET_ERROR, 'Invalid JWT token.')
    return
  }

  const page = '/users/'+payload.code+'/password'
  Object.assign(item, { 'token': resetToken })
  return fetch(page, { method: 'PATCH', body: JSON.stringify(item) })
    .then(response => {})
    .then(() => {
      commit(types.RESET_PASSWORD_TOGGLE_LOADING)
      commit(types.RESET_PASSWORD_SET_UPDATED, true)
    })
    .catch((e) => {
      commit(types.RESET_PASSWORD_TOGGLE_LOADING)
      commit(types.RESET_PASSWORD_SET_ERROR, e.message)
    })
}

export const checkToken = ({ commit }, resetToken) => {
  commit(types.RESET_PASSWORD_TOGGLE_LOADING)

  if (resetToken === 'undefined' || !resetToken) {
    commit(types.RESET_PASSWORD_TOGGLE_LOADING)
    commit(types.RESET_PASSWORD_SET_INVALID_TOKEN, true)
    return
  }

  let payload = null

  try {
    payload = Vue.$jwt.decode(resetToken)

    if (!payload || payload.subject !== 'reset password') {
      commit(types.RESET_PASSWORD_TOGGLE_LOADING)
      commit(types.RESET_PASSWORD_SET_INVALID_TOKEN, true)
      return
    }
  } catch (e) {
    commit(types.RESET_PASSWORD_TOGGLE_LOADING)
    commit(types.RESET_PASSWORD_SET_INVALID_TOKEN, true)
    return
  }

  const currentTime = new Date().getTime() / 1000
  if (currentTime > payload.exp) {
    commit(types.RESET_PASSWORD_SET_EXPIRED_TOKEN, true)
  }
  commit(types.RESET_PASSWORD_TOGGLE_LOADING)
}

export const reset = ({ commit }) => {
  commit(types.RESET_PASSWORD_RESET)
}
