<template>
  <div>
    <b-card class="col-8" border-variant="light" bg-variant="light">
      <b><u>Transfer History:</u></b>
      <b-container fluid>
        <b-table
          show-empty
          stacked="md"
          id="airtableHistoryTable"
          ref="airtableHistoryTable"
          :items="airtableHistory"
          :fields="visibleFields"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :empty-text="emptyText"
          :sort-icon-left="true">

          <template v-slot:cell(options)="row">
            <div class="options-holder right">
              <b-btn v-if="row.item.status !== 'completed' && row.item.status !== 'failed'"
                     @click="onClickRefresh()"
                     class="mt-1 mr-1 my-1"
                     alt="Refresh Status"
                     title="Refresh Status">
                <font-awesome-icon icon="sync"/>&nbsp;&nbsp;&nbsp;Refresh
              </b-btn>
            </div>
          </template>
          <template #table-busy>
            <small>Fetching Transfer History...</small>
            <i class="fa fa fa-spin fa-spinner ml-2"></i>
          </template>
        </b-table>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import * as dateFormatHelper from "../../../../helpers/date_format_helper";

export default {
  props: {
    // Boolean indicating project/user context
    userProfile: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      history: [],
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      emptyText: "No Transfers Yet!",

      fieldList: [
        {
          key: 'projectName', label: 'Project Name', sortable: true, class: "text-left", visible: this.userProfile,
          formatter: (key, val, item) => {
            return item.project.name
          },
        },
        {
          key: 'createdAt', label: 'Date Created', sortable: true, class: "text-left", visible: true,
          formatter: (val) => {
            return dateFormatHelper.displayFormattedDate(val)
          },
        },
        {
          key: 'status', label: 'Status', sortable: false, class: "text-center", visible: true,
          formatter: (val) => {
            return val.charAt(0).toUpperCase() + val.slice(1);
          },
        },
        {key: 'observationCount', label: 'Records Sent', sortable: false, class: "text-center", visible: true,},
        {key: 'duration', label: 'Transfer Time (seconds)', sortable: false, class: "text-center", visible: true,},
        {key: "options", label: "Options", class: "text-right", visible: true,},
      ],

    }
  },

  mounted() {
    this.$root.$on('refreshHistory', data => {
      this.onClickRefresh()
    });
  },

  computed: {
    ...mapGetters({
      userDataSession: "user/profile/sessionData",
      project: "project/get/project",
    }),

    visibleFields() {
      // Property returns only the fields where visible = true
      return this.fieldList.filter(field => field.visible)
    },
  },

  methods: {
    ...mapActions({
      createHistory: "integration_airtable/data/createHistory",
      getUserHistory: "integration_airtable/data/getUserHistory",
      getProjectHistory: "integration_airtable/data/getProjectHistory",
    }),

    onClickRefresh() {
      this.$refs.airtableHistoryTable.refresh();
    },

    airtableHistory(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {userId: this.userDataSession.id, projectId: this.project.id, urlParams};

      if (this.userProfile) {
        return this.getUserHistory(data).then((data) => {
          this.totalItems = data["hydra:totalItems"];
          this.currentPage = ctx.currentPage || 1;
          this.sortBy = ctx.sortBy || "";
          this.isBusy = false;

          this.history = data["hydra:member"].map(item => {
            return {
              ...item,
            }
          })
          // Return all records for user profile page
          return this.history
        });
      } else {
        return this.getProjectHistory(data).then((data) => {
          this.totalItems = data["hydra:totalItems"];
          this.currentPage = ctx.currentPage || 1;
          this.sortBy = ctx.sortBy || "";
          this.isBusy = false;

          this.history = data["hydra:member"].map(item => {
            return {
              ...item,
            }
          })
          return this.history
        });
      }
    },
  },

};
</script>

<style lang="scss">

</style>
