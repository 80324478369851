<template>
  <div>
    <div class="card mb-3">
      <div class="card-body p-2 p-sm-2">
        <div class="media forum-item">
          <div class="media-body">
            <b-link :to="{ name: 'ProjectForumDiscussion',
                           params: { post:discussionLocal.id }}"
                    class="font-weight-bold font-xlarge"
                    :alt="'View this Forum Post'"
                    :title="'View this Forum Post'">
              {{ discussionLocal.subject }}
            </b-link>
            <div class="mt-2" v-html="this.ellipsize(purify(discussionLocal.discussionText), 100)"></div>
            <div class="row justify-content-between mr-2">
              <div class="col-lg-5 col-md-5">
                <div class="small mb-2">
                  Posted By: <b>{{ discussionLocal.user.displayName }}</b> on
                  <b>{{ displayDate(discussionLocal.createdAt) }}</b>
                </div>
              </div>
              <div class="col-lg-5 col-md-5" v-if="latestReply">
                <div class="small mb-2">
                  Last Activity: <b>{{ latestReply.user.displayName }}</b> replied on
                  <b>{{ displayDate(latestReply.updatedAt) }}</b>
                </div>
              </div>
            </div>
          </div>
          <div class="text-muted small text-center align-self-center">
            <b-link v-if="repliesCount > 0" :to="{ name: 'ProjectForumDiscussion',
                           params: { post:discussionLocal.id }}"
                    :alt="'View this Forum Post and Replies'"
                    :title="'View this Forum Post and Replies'">
              <span class="font-large"><i class="fa fa-comment fa-1x ml-2"></i> {{ repliesCount }}</span>
            </b-link>
            <span v-else class="font-large"><i class="fa fa-comment fa-1x ml-2"></i> {{ repliesCount }}</span>
            <div class="row mt-2">
              <div class="col">
                <b-badge class="float-right" v-if="isManager && discussionLocal.isHidden"
                         title="Not Visible to Members"
                         alt="Not Visible to Members"
                         variant="secondary">Email Only Forum Post
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer p-1 bg-transparent" v-if="discussionLocal && discussionLocal.tags">
        <div class="row h5 justify-content-start">
          <div class="col-md-auto align-middle mb-1 mt-1" v-for="(tag, index) in discussionLocal.tags" :key="index">
            <b-button pill size="sm"
                      variant="tertiary"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Filter Forum Posts by this Tag"
                      @click="handleTagClick(tag)">
              <span><b>{{ tag }} </b></span>
            </b-button>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DOMPurify from 'dompurify';

export default {
  name: "parentPost",
  components: {},
  props: {
    // Incoming discussion
    discussion: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DOMPurify: DOMPurify,
      discussionLocal: this.discussion,
      isReplying: false,
      isShowingDetails: false,
      options: [],
    };
  },
  watch: {
    discussion: {
      immediate: true,
      handler(newVal, oldVal) {
        this.lastReply = newVal
      }
    },
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isMember: "project/get/isMember",
      isManager: "project/get/isManager",
    }),
    repliesCount: {
      get() {
        return this.discussionLocal.repliedPosts.length > 0 ? this.discussionLocal.repliedPosts.length : 0
      }
    },
    latestReply() {
      if (this.repliesCount === 0) {
        return null
      }
      let latestDate = this.discussionLocal.repliedPosts.reduce((a, b) => a.updatedAt > b.updatedAt ? a : b).updatedAt
      latestDate = new Date(latestDate);
      let lastReply =  this.discussionLocal.repliedPosts.filter((post) => {
        let date = new Date(post.updatedAt);
        return date.getTime() === latestDate.getTime();
      });
      return lastReply[0]
    },
  },

  methods: {
    ...mapActions({}),
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
    handleTagClick(clickedTag) {
      this.$emit('tagClicked', clickedTag)
    }
  },
}
</script>

<style scoped lang="scss">
.font-xlarge {
  font-size: x-large;
}

.font-large {
  font-size: large;
}
</style>
