import Register from '../components/user/Register'
import Login from '../components/user/Login'
import Logout from '../components/user/Logout'
import LogoutEmailLogin from "../components/user/LogoutEmailLogin.vue";
import ForgotPassword from '../components/user/ForgotPassword'
import ResetPassword from '../components/user/ResetPassword'
import UserProfile from '../components/user/Profile'
import Activate from '../components/user/Activate'
import VerifyEmail from '../components/user/VerfiyEmail'
import LoginSocial from '../components/user/LoginSocial'
import AuthorizeAirtable from '../components/project/view/airtable/AirtableAuthorize.vue'
import UserDelete from '../components/user/Delete'

export default [
  {name: 'Register', path: '/register/', component: Register, meta: {toHomeIfAuthenticated: true}},
  {name: 'UserProfile', path: '/users/profile', component: UserProfile, meta: {requiresAuth: true}},
  {name: 'Login', path: '/login/', component: Login, meta: {toHomeIfAuthenticated: true}},
  {name: 'Logout', path: '/logout/', component: Logout, meta: {toHomeIfAuthenticated: false}},
  {name: 'LogoutEmailLogin', path: '/logout/email', component: LogoutEmailLogin, meta: {toHomeIfAuthenticated: false}},
  {name: 'Activate', path: '/activate/:token?', component: Activate, meta: {requiresAuth: false}},
  {name: 'VerifyEmail', path: '/register/verify', component: VerifyEmail, meta: {requiresAuth: false}},
  // { name: 'LoginGoogle', path: '/login/google', component: LoginSocial, meta: { toHomeIfAuthenticated: true }},
  // { name: 'LoginSciStarter', path: '/login/scistarter', component: LoginSocial, meta: { toHomeIfAuthenticated: true }},
  {
    name: 'AuthorizeAirtable',
    path: '/integrations/airtable/authorize',
    component: AuthorizeAirtable,
    meta: {requiresAuth: true}
  },
  {name: 'Delete', path: '/users/profile/delete', component: UserDelete, meta: {requiresAuth: true}},

  {name: 'ForgotPassword', path: '/forgot-password/', component: ForgotPassword, meta: {toHomeIfAuthenticated: true}},
  {name: 'UpdatePassword', path: '/update-password/', component: ForgotPassword, meta: {toHomeIfAuthenticated: true}},
  {
    name: 'ResetPassword',
    path: '/reset-password/:token?',
    component: ResetPassword,
    meta: {toHomeIfAuthenticated: true}
  },
]
