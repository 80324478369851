<template>
  <div class="card mb-3">
    <div class="card-body p-2 p-sm-3">
      <div class="media forum-item">
        <div class="media-body">
          <small class="text-muted" v-html="purify(this.ellipsize(reply.discussionText, 100))"></small>
          <div class="row justify-content-between mr-2">
            <div class="col-lg-5 col-md-5">
              <div class="small mb-2">
                Posted By: <b>{{ reply.user.displayName }}</b> on
                <b>{{ displayDate(reply.createdAt) }}</b>
              </div>
            </div>
          </div>
          <b-link :to="{ name: 'ProjectForumDiscussionReply',
                           params: { post: reply.parentPost.id, reply: reply.id }}"
                  :alt="'View this Forum Post In Context'"
                  :title="'View this Forum Post In Context'">View In Context
          </b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DOMPurify from 'dompurify';

export default {
  name: "childPost",
  components: {},
  props: {
    // Incoming reply
    reply: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DOMPurify: DOMPurify,
      isReplying: false,
      isShowingDetails: false,
      options: [],
    };
  },

  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isMember: "project/get/isMember",
      isManager: "project/get/isManager",
    }),
  },

  methods: {
    ...mapActions({}),
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">

</style>
