<template>
  <b-container style="height:75vh;">
    <b-row class="justify-content-md-center">
      <b-col col lg="8">
        <h1 v-if="isUpdatePassword">Update Your Password</h1>
        <h1 v-else>Forgot Password</h1>
        <b-card bg-variant="light">
          <b-form @submit.prevent="onForgotPassword" novalidate>
            <b-form-group class="mb-0">
              <div v-if="isUpdatePassword" class="p-down">
                <p class="p-down">We've recently updated our platform to be more secure. Given this
                  improved security, we need you to update your password. Please follow the steps below:
                </p>
                <ol>
                  <li>Enter your email address (<strong>case sensitive</strong>)</li>
                  <li>Next, we'll send you a password reset link by email</li>
                  <li>Click the link in the email and enter a new password</li>
                  <li>Use your username and new password to login</li>
                </ol>
              </div>
              <div v-else class="p-down">
                <p>Please enter your email address below (case sensitive) so we can send you
                  a secure link for you to use to to reset your forgotten password.
                </p>
              </div>

              <p v-if="isUpdatePassword" class="p-down">Thanks for helping us make CitSci more secure!</p>
              <p v-else class="p-down">Thanks!</p>

              <b-form-group
                id="email-input-group"
                label="Email"
                label-for="email-input"
                label-class="font-weight-bold"
                label-cols-sm="2"
                label-cols-lg="2"
                class="mt-4">
                <b-form-input id="email-input"
                              type="email"
                              name="email"
                              placeholder="Your Email Address"
                              v-model.trim="item.email"
                              v-validate="'required|email'"
                              :class="{ 'is-invalid': isInvalid('email') }"
                              :disabled="isLoading"
                              aria-describedby="email-input-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="email-input-live-feedback">
                  {{ getError('email') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <p class="message-holder" v-show="error">{{ error }}</p>

              <div class="row">
                <div class="col text-center">
                  <b-button type="submit"
                            size="md"
                            class="mx-auto"
                            style="font-weight:bold;"
                            variant="primary"
                            :disabled="!isFilled || isLoading">
                    <font-awesome-icon icon="spinner" pulse v-show="isLoading"/>
                    Send Link To Reset My Password
                  </b-button>
                </div>
              </div>

            </b-form-group>
          </b-form>
        </b-card>
        <router-link :to="{ name: 'Login' }">Back to Login</router-link>
        <br/>
        <br/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters, mapActions} = createNamespacedHelpers('user/forgotPassword')

export default {
  data() {
    return {
      item: {},
      pageTitle: '',
      submitButtonName: '',
      isUpdatePassword: false
    }
  },

  created() {
    this.$validator.validateAll()

    if (this.$route.name === 'UpdatePassword') {
      this.pageTitle = 'Update Password'
      this.submitButtonName = 'Update my password'
      this.isUpdatePassword = true
    } else {
      this.pageTitle = 'Forgot Password'
      this.submitButtonName = 'Reset my password'
      this.isUpdatePassword = false
    }
  },

  computed: {
    ...mapGetters([
      'error',
      'isLoading',
      'violations',
      'sent'
    ]),

    isFilled() {
      return this.item.email && !this.errors.items.length
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    sent: function (sent) {
      if (!sent) {
        return
      }

      this.$router.push({name: 'Login'})
    }
  },

  methods: {
    ...mapActions([
      'sendRequest'
    ]),

    onForgotPassword() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendRequest(this.item)
        }
      })
    },

    isInvalid(key) {
      return (this.violations !== null && Object.keys(this.violations).length > 0 && this.violations[key]) || this.errors.has(key)
    },

    getError(key) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      if (this.violations && Object.keys(this.violations).length > 0 && this.violations[key]) {
        return this.violations.key
      }
    }
  },
}
</script>

<style lang="scss" scoped>

.card-body {
  background-color: #F5F4E8 !important;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00c8f8 !important;
  border-color: #00c8f8 !important; /*00AAEF 00AAEF 00c8f8*/
  opacity: 1.00 !important;
}

.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  color: #fff;
  background-color: #879649 !important;
  border-color: #879649 !important;
  opacity: 1.00 !important;
}

.ResetPassword {
  width: 160px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: bold !important;
}

</style>
