import fetch from '../../../utils/fetch'
import SubmissionError from "../../../error/SubmissionError";
import {dateForServer} from "../../../helpers/date_format_helper";

const initialState = {
    isLoading: false,
    orgProjects: null,
    error: null,
    context: {
      type: null,
      id: null,
      url: null
    },
}

const getters = {
    orgProjects( state ) { return state.orgProjects },
    approved(state) {
      if (state.orgProjects) {
        return {...state.orgProjects.filter((op) => op.approved === true)}
      } else {
        return null
      }
    },
    pending(state) {
      if (state.orgProjects) {
        return {...state.orgProjects.filter((op) => !op.approved )}
      } else {
        return null
      }
    },
    denied(state) {
      if (state.orgProjects) {
        return {...state.orgProjects.filter((op) => op.approved === false)}
      } else {
        return null
      }
    },
    isLoading(state) { return state.isLoading },
    error(state) { return state.error },
}

const actions = {
    /**
     * @function get- retrieve
     * @param commit
     * @param context
     * @param orgId {String}
     * @returns {Promise<T | never> | * | *}
     */
    get({ commit, state, rootState }, context) {
      if (context === 'organizations' && rootState.organization.get) {
        commit(types.SET_ID_URL, { id: rootState.organization.get.organization.url, type: context})
      }
      if (context === 'projects' && rootState.project.get.id) {
        commit(types.SET_ID_URL, { id: rootState.project.get.id, type: context})
      }
      if (!state.context) return

      commit(types.SET_ORGANIZATION_PROJECTS_LOADING);
      return fetch(state.context.url + state.context.id + '/organization_projects')
        .then(response => response.json())
        .then((data) => {
          commit(types.SET_ORGANIZATION_PROJECTS, data['hydra:member'])
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_PROJECTS_ERROR, error)
        })
    },

    createAffiliation({commit, state, dispatch}, request) {
      commit(types.SET_ORGANIZATION_PROJECTS_LOADING);
      return fetch('/organization_projects', {method: 'POST', body: JSON.stringify(request)})
        .then(response => response.json())
        .then((data) => {
          dispatch('refresh')
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_PROJECTS_ERROR, error)
        })
    },

    approveDenyAffiliation({dispatch, commit, state, rootState}, orgRequest) {
      commit(types.SET_ORGANIZATION_PROJECTS_LOADING)
      let approveDeny =
        { approved: orgRequest.approveDeny,
          approver: rootState.user.profile.sessionData['@id'],
        }
      return fetch('/organization_projects/' + orgRequest.id, {
        method: 'PATCH',
        body: JSON.stringify(approveDeny)
      })
        .then(response => response.json())
        .then((data) => {
          dispatch('refresh')
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_PROJECTS_ERROR, error)
        })
    },

    removeAffiliation({commit, state, dispatch}, orgProject) {
      commit(types.SET_ORGANIZATION_PROJECTS_LOADING)
      return fetch('organization_projects/' + orgProject.id, {
        method: 'DELETE',
        headers: new Headers({ 'Content-Type': 'application/ld+json' })
      })
        .then(response => response.json())
        .then((data) => {
          dispatch('refresh')
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_PROJECTS_ERROR, error)
        })
    },

    refresh({ commit, state }) {
      commit(types.SET_ORGANIZATION_PROJECTS_LOADING);
      return fetch(state.context.url + state.context.id + '/organization_projects')
        .then(response => response.json())
        .then((data) => {
          commit(types.SET_ORGANIZATION_PROJECTS, data['hydra:member'])
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_PROJECTS_ERROR, error.message)
        })
    },
}

const types = {
    SET_ID_URL: 'SET_ID_URL',
    SET_ORGANIZATION_PROJECTS_LOADING: 'SET_ORGANIZATION_PROJECTS_LOADING',
    SET_ORGANIZATION_PROJECTS: 'SET_ORGANIZATION_PROJECTS',
    SET_ORGANIZATION_PROJECTS_ERROR: 'SET_ORGANIZATION_PROJECTS_ERROR',
    RESET_ERROR: 'RESET_ERROR',
    RESET: 'RESET',
}

const mutations = {
    [types.SET_ID_URL](state, data) {
      state.context = {
        id: data.id,
        type: data.type,
        url: '/'+data.type+'/'
      };
    },
    [types.SET_ORGANIZATION_PROJECTS_LOADING](state) {
        state.isLoading = true;
    },
    [types.SET_ORGANIZATION_PROJECTS](state, orgProjects) {
        state.orgProjects = orgProjects
        state.isLoading = false
    },
    [types.SET_ORGANIZATION_PROJECTS_ERROR](state, error) {
        state.error = error;
        state.isLoading = false;
    },
    [types.RESET_ERROR](state) {
        state.isLoading = false;
        state.error = false;
    },
    [types.RESET](state) {
        state = initialState
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions: actions,
    mutations: mutations
}
