<template>
  <b-container style="height:75vh;">
    <b-row class="justify-content-md-center">
      <b-col col lg="5">
        <b-card id="activate-loader" bg-variant="light">

          <div v-show="isLoading">
            <font-awesome-icon icon="spinner" pulse/>
            Loading
          </div>

          <div v-if="!isLoading && emailSent">
          <span>
            <b>Verification Email Sent Successfully!</b>
            <br><br>
            Be sure to check the spam folder<br>
            and add us to your safe sender's list.<br><br>
          </span>
            <br>
            <i>Note: Account Verification link's expire after 30 days</i>
          </div>

          <!--User is not verified & Verification link is expired/invalid -->
          <div v-if="!isLoading && (expiredToken || invalidToken) && !verifiedStatus && !emailSent">
          <span>
            <b>Your Account Verification link has expired.</b>
            <br><br>
            Click to receive a new Account Verification link.</span>
            <br>
            <div v-if="!emailSent">
              <b-button type="submit"
                        class="ResendEmail"
                        @click="onClickResendEmail"
                        variant="primary">Resend Verification Email
              </b-button>
              <br><br>
              <i>Note: Account Verification link's expire after 30 days</i>
            </div>
          </div>

          <!--When user is logged in & is already verified-->
          <div v-if="!isLoading && verifiedStatus">
            <span>Your Account is already Verified.</span>
            <br><br>
            <div>
              <b-button type="submit"
                        class="ToProfile"
                        :to="{ name: 'UserProfile' }"
                        variant="primary">Go To My Profile
              </b-button>
            </div>
          </div>

          <div v-if="error">{{ error }}</div>

        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  data() {
    return {
      token: null,
      verifiedStatus: false,
      // isTokenExpired: false,
      // isTokenInvalid: false,
      // isEmailSent: false,
    }
  },
  created() {
    this.token = decodeURIComponent(this.$route.params.token)
    // Subscribe to account verification mutation
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'user/activate/ACTIVATE_ACCOUNT_SET_ACTIVATED') {
        this.verifiedStatus = true
      }
    });
    if (this.isVerified) {
      // User is already verified...
      this.verifiedStatus = true
    }
  },

  mounted() {
    if (!this.verifiedStatus) {
      this.checkToken(this.token).then(() => {
        if (!this.expiredToken && !this.invalidToken) {
          this.update(this.token)
        }
      })
    }
  },

  beforeDestroy() {
    this.reset()
  },

  computed: {
    ...mapGetters({
      error: 'user/activate/error',
      isLoading: 'user/activate/isLoading',
      isVerified: 'user/profile/isVerified',
      userId: 'user/profile/id',
      userEmail: 'user/profile/email',
      activated: 'user/activate/activated',
      expiredToken: 'user/activate/expiredToken',
      invalidToken: 'user/activate/invalidToken',
      isAuthenticated: 'user/auth/isAuthenticated',
      emailSent: 'user/activate/emailSent',
    }),
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    activated: function (activated) {
      if (!activated) {
        return
      }

      if (this.isAuthenticated) {
        this.$router.push({name: 'UserProfile'})
      } else {
        this.$router.push({name: 'Login'})
      }
    },

    // expiredToken (expiredToken) {
    //   if (!expiredToken) {
    //     return
    //   }
    //   this.isTokenExpired = expiredToken
    //
    // },
    //
    // invalidToken (invalidToken) {
    //   console.log(expiredToken)
    //   if (!invalidToken) {
    //     return
    //   }
    //   this.isTokenInvalid = invalidToken
    // },
    //
    // emailSent (sent) {
    //   if(!sent) {
    //     return
    //   }
    //   this.isEmailSent = sent
    //
    // }

  },

  methods: {
    ...mapActions({
      update: 'user/activate/update',
      checkToken: 'user/activate/checkToken',
      reset: 'user/activate/reset',
      resendEmail: 'user/activate/resendEmail',
    }),

    onClickResendEmail() {
      let code = JSON.parse(atob(this.token.split('.')[1])).code
      if (code !== this.userId) {
        // console.log("Mismatched payload and id, send email to correct user")
        code = uid
      }
      this.resendEmail({"id": code, "email": this.userEmail}).then(() => {
        // console.log("ActivateEmailSent")
      })
    },
  }
}
</script>

<style lang="scss" scoped>
#activate-loader {
  text-align: center;
}
</style>
