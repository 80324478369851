<template>
  <div>
    <div class="row justify-content-between">
      <h3 class="ml-3">Manage Assigned Locations</h3>
      <b-button
        class="btn ml-4 mb-2"
        @click="collapse = !collapse"
        variant="secondary"
        h-align="center"
      >
        <font-awesome-icon icon="info"/>
        &emsp;Find out more about Managing Locations
      </b-button>
      <div class="col-2">
        <button class="btn btn-primary"
                @click="() => { this.$bvModal.show('assign-locations-modal') }"
                title="Add Assigned Locations">
          <i class="fa fa-plus text-white"></i>
          Add Assigned Locations
        </button>
      </div>
    </div>

    <!-- Information accordion -->
    <b-collapse class="mt-2 mb-2 border" v-model="collapse" id="">
      <ul>
        <li>
          Assigned locations are useful when you want members to make observations at specific locations
        </li>
        <li>
          You can assign locations to specific members. Assignments can be made for a specific datasheet
          ('Datasheet Specific') or be applicable for all datasheets in your project ('Project Level')
        </li>
        <li>
          Assigned locations appear on datasheets allowing members to pick a location. Datasheets with
          their <b>'Location Format'</b> set to <b>'List of Locations'</b> or <b>'Both'</b> will show a dropdown of assigned
          locations
        </li>
        <li>
          As a manager, you have an option in the datasheet creator to enable auto-assignment of new locations
          to the member making an observation
        </li>
        <li>
          These automatically assigned locations are at the 'Project Level'
        </li>
        <li>
          Members will see their assigned locations for datasheets where the location format is 'List of
          Locations' or 'Both'
        </li>
        <li>
          <span class="text-danger">Please note that member location assignments only show up on our website and do
            not show up on our mobile apps at this time</span>
        </li>
        <li>
          If you want to manage locations for each datasheet irrespective of member assignments, edit the
          datasheet and manage its locations by selecting location format 'List of Locations' or 'Both'
        </li>
        <li>
          This can be useful when you want all project members to see the same list of locations to choose from and you do not
          want to assign a specific member to any of these locations
        </li>
      </ul>
    </b-collapse>

    <div class="card-body">
      <div class="row mb-3">
        <div class="col-lg-8 col-sm-12 col-md-10">
          <div class="row mb-3 justify-content-between">
            <div class="input-group col-12">
              <b-input-group-prepend>
              <span class="btn btn-secondary" title="Search assigned location's by">
                <i v-if="!isBusy" class="fas fa-search"></i>
                <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
              </span>
              </b-input-group-prepend>
              <b-input-group-prepend class="col-3 m-0 p-0">
                <b-form-select v-model="filterBy" :options="searchFields"></b-form-select>
              </b-input-group-prepend>
              <b-form-input
                class="form-control"
                :placeholder=searchPlaceholder
                name="search"
                type="search"
                debounce="1000"
                v-model="searchText"
              ></b-form-input>
              <div class="input-group-append" v-if="searchText">
                <b-button @click="searchText = ''"
                ><i class="fas fa-times-circle"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p v-if="isSaving">
        Saving new assigned locations...
        <i class="fas fa-spinner fa-spin"></i>
      </p>
      <div class="row justify-content-between">
        <b-col md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} assigned location<span
          v-if="totalItems === 0 || totalItems > 1"
        >s</span
        >
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </div>
    </div>

    <b-table show-empty
             stacked="md"
             id="assignedLocationsManageTable"
             ref="assignedLocationsManageTable"
             :busy="isBusy"
             :items="assignments"
             :fields="tableFields"
             :filter="filterVal"
             :current-page="currentPage"
             :per-page="perPage"
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection"
             :empty-text="emptyText"
    >
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Assigned Locations..."/>
      </template>
      <template #cell(type)="row">
        {{ row.item.datasheet ? 'Datasheet Specific' : 'Project Level' }}
      </template>
      <template #cell(location.name)="row">
        {{ row.item.location.name }} ({{ row.item.location.latitude }}, {{ row.item.location.longitude }})
      </template>
      <template v-slot:cell(options)="row">
        <div class="options-holder float-right">
          <b-btn
            size="sm"
            class="my-1 mr-1"
            @click="deleteAssignment(row.item)"
            alt="Remove this Assigned Location"
            title="Remove this Assigned Location"
          >
            <font-awesome-icon icon="times"/>
            Delete
          </b-btn>
        </div>
      </template>
    </b-table>

    <AssignLocationsModal @on-add-assignments="onAddAssignments"></AssignLocationsModal>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader.vue";
import RequiresMember from "../tabpermission/requiresMember.vue";
import AssignLocationsModal from "./assignLocationsModal.vue";
import DatasheetSelection from "../zooniverse/datasheetSelection.vue";
import ZooniverseUsername from "../zooniverse/username.vue";
import ZooniverseProject from "../zooniverse/project.vue";

export default {
  components: {
    ZooniverseProject, ZooniverseUsername, DatasheetSelection,
    AssignLocationsModal,
    RequiresMember,
    VueLoader
  },
  data() {
    return {

      collapse: false,

      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      isSaving: false,
      searchFields: [
        {value: "member.name", text: "Member Name"},
        {value: "datasheet.name", text: "Datasheet Name"},
        {value: "location.name", text: "Location Name"},
      ],
      tableFields: [
        {key: "member.member.firstName", label: "First Name", sortable: true, sortDirection: "asc",},
        {key: "member.member.lastName", label: "Last Name", sortable: true, sortDirection: "asc",},
        {key: "type", label: "Assignment Type", sortable: false, sortDirection: "asc",},
        {key: "datasheet.name", label: "Datasheet", sortable: true, sortDirection: "asc",},
        {key: "location.name", label: "Location", sortable: true, sortDirection: "asc",},
        {
          key: "updatedAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "desc",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {key: "options", label: "Options", sortable: false, class: "text-right"}
      ],
      emptyText: "No Assigned Locations Yet",
      searchText: "",
      filterBy: "member.name",
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "updatedAt",
      sortDesc: true,
      sortDirection: "desc",
    };
  },

  created() {
    if (this.projectVuex) {
      if (this.$refs.assignedLocationsManageTable)
        this.$refs.assignedLocationsManageTable.refresh()
    }
  },
  watch: {
    projectVuex(newVal) {
      if (newVal) {
        if (this.$refs.assignedLocationsManageTable)
          this.$refs.assignedLocationsManageTable.refresh()
      }
    },
    filterBy(newVal) {
      if (newVal) {
        this.searchText = ""
      }
    }
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      isPublicOrMember: "project/get/isPublicOrMember",
      membershipMap: "user/profile/membershipMap",
      isLoading: "project/assigned_locations/isLoading",
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },
    searchPlaceholder() {
      let placeholder = "Search assigned location's by "
      if (this.searchFields && this.filterBy) {
        placeholder = placeholder + this.searchFields.filter(val => {
          return val.value === this.filterBy
        })[0].text
      }
      return placeholder
    },
    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search by user first & last name, location name and datasheet name

        let retVal = ''
        if (this.searchText.length > 0) {
          switch (this.filterBy) {
            case "member.name":
              retVal = retVal.length === 0 ? 'searchMember=' + this.searchText : retVal + '&searchMember=' + this.searchText;
              break;
            case "location.name":
              retVal = retVal.length === 0 ? 'location.name=' + this.searchText : retVal + '&location.name=' + this.searchText;
              break;
            case "datasheet.name":
              retVal = retVal.length === 0 ? 'datasheet.name=' + this.searchText : retVal + '&datasheet.name=' + this.searchText;
              break;
          }
          this.hideReplies = false
        }
        return retVal
      }
    },
  },
  methods: {
    ...mapActions({
      getAssignedLocations: "project/assigned_locations/getManager",
      createAssignedLocation: "project/assigned_locations/create",
      deleteAssignedLocation: "project/assigned_locations/delete",
    }),

    assignments(ctx) {
      // Busy state is set
      this.isBusy = true;

      // URL Params are set
      let urlParams = this.urlBuilder(ctx);

      // Assigned locations are fetched
      return this.getAssignedLocations(urlParams).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(assignments => {
          return {
            ...assignments
          };
        });
      });
    },

    // Handle return from create modal
    onAddAssignments(data) {
      console.log('save assigned locations', data)

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('assign-locations-modal')
      })
      this.isSaving = true

      let promises = []
      data.forEach((val) => {
        let promise = this.createAssignedLocation(val)
        promises.push(promise)
      })

      // wait till all requests are complete before reloading table
      Promise.all(promises).then((values) => {
        console.log('all assignments added', values)
        this.isSaving = false
        this.$refs.assignedLocationsManageTable.refresh();
      })
    },

    deleteAssignment(item) {
      this.deleteAssignedLocation(item['@id']).then(val => {
        if (val) this.$refs.assignedLocationsManageTable.refresh();
      })
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
