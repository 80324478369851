<template>
  <b-container class="h-75 container-height">
    <div class="login text-center mt-5">
      <font-awesome-icon icon="spinner" pulse v-show="isLoading || isLoadingSession"/>
      <h3 v-if="authType === 'google'">Logging you in with Google <i class="fab fa-google"></i> ... Please wait...</h3>
      <h3 v-if="authType === 'scistarter'">Logging you in with SciStarter
        <b-img src='/assets/images/SciStarter_v0.png'/>
        ... Please wait...
      </h3>
      <p class="message-holder" v-show="error">{{ error }}</p>
    </div>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
// import { googleOIDC, sciStarterOIDC } from "../../../main"

export default {
  data() {
    return {
      authType: null,
    }
  },

  created() {

    let pathname = window.location.pathname
    let OIDC = null

    switch (this.$router.options.routes.find(i => i.path === pathname).name) {
      case 'LoginGoogle':
        this.authType = 'google'
        // OIDC = googleOIDC
        break

      case 'LoginSciStarter':
        this.authType = 'scistarter'
        // OIDC = sciStarterOIDC
        break

      default:
        // Error
        this.error = "Unrecognized Social Auth..."
    }
    // Process and validate the response
    // - then call our POST endpoint with authCode and codeVerifier
    // if (OIDC) {
    //   OIDC.readSigninResponseState(window.location.href, null, true).then(({state, response}) => {
    //     // console.log(state, response)
    //     // Validate the response against the state in sessionStorage
    //     OIDC._validator._processSigninParams(state, response).then((response) => {
    //       // console.log(response)
    //       let creds = {authcode: response.code, pkcecodeverifier: state.code_verifier}
    //       this.authenticate({page: pathname, creds: creds})
    //     })
    //   }).catch(function (err) {
    //     console.log(err)
    //   })
    // }

  },

  computed: {
    ...mapGetters({
      error: 'user/auth/error',
      isLoading: 'user/auth/isLoading',
      isAuthenticated: 'user/auth/isAuthenticated',
      violations: 'user/auth/violations',
      isLoadingSession: 'user/profile/isLoadingSession',
    }),

  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    isAuthenticated: function (isAuthenticated) {
      if (!isAuthenticated) {
        return
      }

      this.retrieveUser().then(() => {
        // this.$router.push({ name: 'UserProfile' })

        let referrer = this.$route.query.redirect || '/users/profile'

        // Modified to /404 as we have GUID's with 404 in them (e.g. in projects/show/80727ae1-62d9-4048-b2d8-9f1bab6d31b7)
        if (referrer.indexOf('logout') !== -1 || referrer.indexOf('/404') !== -1) {
          referrer = 'users/profile'
        }

        this.$router.push({path: referrer})
      })
    },

  },

  methods: {
    ...mapActions({
      authenticate: 'user/auth/authenticate',
      retrieveUser: 'user/profile/fetchSession',
    }),

  }
}

</script>
