import fetch from '../../../../utils/fetch'

const initialState = {
  user: null, // zooniverse user account info
  history: [],
  isLoadingUser: false,
  isLoadingProjectId: false,
  isLoadingDatasheets: false,
  messageLoadingUser: null,
  messageLoadingProject: null,
  messageLoadingDatasheets: null,
}
// console.log('zooniverse store')
const getters = {
  isLoadingUser(state) {
    return state.isLoadingUser
  },
  isLoadingProjectId(state) {
    return state.isLoadingProjectId
  },
  isLoadingDatasheets(state) {
    return state.isLoadingDatasheets
  },
  messageLoadingUser(state) {
    return state.messageLoadingUser
  },
  messageLoadingProject(state) {
    return state.messageLoadingProject
  },
  messageLoadingDatasheets(state) {
    return state.messageLoadingDatasheets
  },

  historyForDatasheet: (state) => (datasheetId) => {
    return state.history.filter((transfer) => {
      if (datasheetId === 'all') {
        return true
      } else return datasheetId === transfer.datasheetId;
    })
  },

}
const actions = {
  setUser({commit, dispatch, rootState, rootGetters}, zooniverseUsername) {
    commit(types.SET_LOADING_USER)

    return fetch('/users/' + rootState.user.profile.sessionData['id'] + '/zooniverse',
      {
        method: 'POST',
        body: JSON.stringify({zooniverseUsername: zooniverseUsername})
      })
      .then(response => response.json())
      .then((data) => {
        console.log({setUser: data})

        const member = {
          projectId: rootGetters['project/get/id'],
          id: rootGetters["user/profile/membershipMap"][rootGetters['project/get/id']].id,
          isZooniverseCollaborator: true,
        }

        dispatch('project/show/updateProjectMember', member, {root: true}).then(() => {
          commit(types.SET_USER, data)
          commit('project/get/SET_PROJECT', {
            ...rootGetters['project/get/project'],
            zooniverseUserName: data.login,
            zooniverseUserDisplayName: rootState.user.profile.sessionData['displayName'],
          }, {root: true})

        }).catch((e) => {
          commit(types.SET_ERROR_USER, e.message)
        })
      })
      .catch((e) => {
        commit(types.SET_ERROR_USER, e.message)
      })
  },

  setCollaborator({commit, dispatch, rootState, rootGetters}) {
    commit(types.SET_LOADING_USER)

    const member = {
      projectId: rootGetters['project/get/id'],
      id: rootGetters["user/profile/membershipMap"][rootGetters['project/get/id']].id,
      isZooniverseCollaborator: true,
    }

    dispatch('project/show/updateProjectMember', member, {root: true}).then(() => {
      // commit(types.SET_USER, data)
      commit('project/get/SET_PROJECT', {
        ...rootGetters['project/get/project'],
        zooniverseUserName: rootState.user.profile.sessionData['zooniverseUsername'],
        zooniverseUserDisplayName: rootState.user.profile.sessionData['displayName'],
      }, {root: true})

    }).catch((e) => {
      commit(types.SET_ERROR_USER, e.message)
    })
  },


  setProjectId({commit, rootGetters}) {
    commit(types.SET_LOADING_ZOONIVERSE_PROJECT_ID)
    return fetch('/projects/' + rootGetters['project/get/id'] + '/zooniverse', {
      body: JSON.stringify({}),
      method: 'POST'
    })
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_ZOONIVERSE_PROJECT_ID)
        commit('project/get/SET_PROJECT', {
          ...rootGetters['project/get/project'],
          zooniverseID: data,
        }, {root: true})
      })
      .catch((e) => {
        commit(types.SET_ERROR_ZOONIVERSE_PROJECT_ID, e.message)
      })
  },
  syncDatasheets({commit, dispatch, rootGetters}, datasheets) {
    commit(types.SET_LOADING_DATASHEETS)
    return fetch('/projects/' + rootGetters['project/get/id'] + '/zooniverse_datasheets',
      {
        method: 'POST',
        body: JSON.stringify({datasheets: datasheets})
      })
      .then(response => response.json())
      .then((data) => {
        dispatch('project/get/refresh', null, {root: true})
        commit(types.SET_DATASHEETS, data)
      })
      .catch((e) => {
        commit(types.SET_ERROR_DATASHEETS, e.message)
      })
  },
}
const types = {
  SET_LOADING_USER: 'SET_LOADING_USER',
  SET_LOADING_ZOONIVERSE_PROJECT_ID: 'SET_LOADING_ZOONIVERSE_PROJECT_ID',
  SET_LOADING_DATASHEETS: 'SET_LOADING_DATASHEETS',
  SET_USER: 'SET_USER',
  SET_ZOONIVERSE_PROJECT_ID: 'SET_ZOONIVERSE_PROJECT_ID',
  SET_DATASHEETS: 'SET_DATASHEETS',
  SET_HISTORY: 'SET_HISTORY',
  SET_ERROR_USER: 'SET_ERROR_USER',
  SET_ERROR_ZOONIVERSE_PROJECT_ID: 'SET_ERROR_ZOONIVERSE_PROJECT_ID',
  SET_ERROR_DATASHEETS: 'SET_ERROR_DATASHEETS',
  RESET: 'RESET',
}
const mutations = {
  [types.SET_LOADING_USER](state) {
    state.isLoadingUser = true;
    state.messageLoadingUser = null;

  },
  [types.SET_LOADING_ZOONIVERSE_PROJECT_ID](state) {
    state.isLoadingProjectId = true;
    state.messageLoadingUser = null;

  },
  [types.SET_LOADING_DATASHEETS](state) {
    state.isLoadingDatasheets = true;
    state.messageLoadingUser = null;
  },

  [types.SET_USER](state, user) {
    state.isLoadingDatasheets = false;
    state.user = user
  },
  [types.SET_ZOONIVERSE_PROJECT_ID](state) {
    state.isLoadingProjectId = false;
  },
  [types.SET_DATASHEETS](state, history) {
    state.history = history
    state.isLoadingDatasheets = false;
  },
  [types.SET_HISTORY](state, transfers) {
    state.history = transfers
  },
  [types.SET_HISTORY](state, datasheets) {
    state.history = datasheets.reduce((history, datasheet) => {
      const modifiedHistory = datasheet.zooniverseHistory.map((history) => {
        return {
          ...history,
          datasheetId: datasheet['id']
        }
      })
      return [...history, ...modifiedHistory];
    }, [])
  },

  [types.SET_ERROR_USER](state, error) {
    state.isLoadingUser = false;
    state.messageLoadingUser = error
  },
  [types.SET_ERROR_ZOONIVERSE_PROJECT_ID](state, error) {
    state.isLoadingProjectId = false;
    state.messageLoadingProject = error

  },
  [types.SET_ERROR_DATASHEETS](state, error) {
    state.isLoadingDatasheets = false;
    state.messageLoadingDatasheets = error

  },
  [types.RESET](state) {
    state = initialState;
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
