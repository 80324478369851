<template>
  <div>
    <div class="card mb-3" v-if="localParent && !isLoadingDelete">
      <div class="card-header">
        <h4>{{ localParent.subject }}</h4>
      </div>
      <div class='row no-gutters bg-light border-bottom' v-if="localParent && localParent.files.length !== 0">
        <div class='col-lg-2 col-md-3 col-sm-4' v-for="(postFile, postFileIndex) in localParent.files">
          <img :src='postFile.path' class="img-fluid" alt="forum post image">
        </div>
      </div>
      <div class="card-body">
        <div class="initial-post">
          <div v-html="purify(localParent && localParent.discussionText)"></div>
          <div class="text-muted small text-center align-self-center float-right"
               v-if="canDelete">
            <b-button size="sm" title="Delete this post" variant="danger" @click="deleteParentModal">
              <i class="fa fa-trash fa-1x"></i>
            </b-button>
          </div>
          <div class="small">
            <b>{{ localParent && localParent.user.displayName }}</b> posted on
            <b>{{ displayDate(localParent && localParent.updatedAt) }}</b>
          </div>
        </div>
      </div>
      <div class="ml-2" v-if="localParent && localParent.tags">
        <div class="row h5">
          <div class="col-md-auto" v-for="(tag, index) in localParent.tags" :key="index">
            <span class="badge badge-pill badge-secondary">{{ tag }}</span>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="localParent.isHidden">
        <div class="row mt-2">
          <div class="col-6">
            <span>This is an Email Only Forum Post. Replies are Locked.</span>
          </div>
          <div class="col-6">
            <b-badge class="float-right"
                     title="Not Visible to Members"
                     alt="Not Visible to Members"
                     variant="secondary">Email Only Forum Post
            </b-badge>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
        <i class="fa fa-spin fa-spinner fa-3x mr-3 mt-3 mb-3 ml-3"></i> Deleting forum post...
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import DOMPurify from 'dompurify';
import RequiresMember from "../../tabpermission/requiresMember.vue";

export default {
  name: "parent",
  components: {
    RequiresMember,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localParent: this.post,
      deleteModalId: 'delete-forum-post-' + this.post.id,
      isLoadingDelete: false,
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      projectId: "project/get/id",
      isManager: "project/get/isManager",
      isMember: "project/get/isMember",
      membershipMap: "user/profile/membershipMap",
    }),
  },

  methods: {
    ...mapActions({
      deletePost: "project/forum/delete"
    }),
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
    canDelete() {
      if (this.isManager) return true
      if (this.isMember) {
        return this.membershipMap[this.projectId] && this.membershipMap[this.projectId].userId === this.localParent.userId
      }
      return false
    },
    deleteParentModal() {
      this.$bvModal.msgBoxConfirm(
        'Do you want to delete this Forum Post?' +
        'The replies to this post will also be deleted.' +
        'This cannot be undone.', {
          title: 'Delete Forum Post',
          size: 'md',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: 'Yes, Delete',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.isLoadingDelete = true
            this.deletePost({id: this.localParent.id}).then(val => {
              if (val) {
                this.$nextTick(() => {
                  // Return to forum page once parent post is deleted
                  this.$router.push({name: 'ProjectForum', params: {id: this.projectVuex.urlField}, replace: true});
                });
                this.localParent = null
                this.$nextTick(() => {
                  this.isLoadingDelete = false;
                });
              }
            })
          }
        })
        .catch(err => {
          console.log('error: ', err)
        })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
