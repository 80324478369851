<template>
  <b-container class="h-75 container-height">
    <b-row class="justify-content-md-center">
      <b-col col lg="6" md="8" sm="12">
        <h1>Sign Up</h1>
        <b-card bg-variant="light" class="mt-1">
          <b-card-body class="special">
            <b-form @submit.prevent="onRegister" novalidate>
              <b-form-group class="mb-0">
                <b-form-group id="firstname-input-group"
                              label="First Name"
                              label-for="firstname-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="firstname-input"
                                type="text"
                                name="firstName"
                                placeholder="First name (at least 1 character)"
                                v-model.trim="item.firstName"
                                v-validate="'required'"
                                data-vv-as="First Name"
                                :class="{ 'is-invalid': isInvalid('firstName') }"
                                :disabled="isLoading"
                                aria-describedby="firstname-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="firstname-input-live-feedback">
                    {{ getError('firstName') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="lastname-input-group"
                              label="Last Name"
                              label-for="lastname-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="lastname-input"
                                type="text"
                                name="lastName"
                                placeholder="Last name (at least 2 characters)"
                                v-model.trim="item.lastName"
                                v-validate="'required|min:2'"
                                data-vv-as="Last Name"
                                :class="{ 'is-invalid': isInvalid('lastName') }"
                                :disabled="isLoading"
                                aria-describedby="lastname-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="lastname-input-live-feedback">
                    {{ getError('lastName') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="email-input-group"
                              label="Email"
                              label-for="email-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="email-input"
                                type="email"
                                name="email"
                                placeholder="Please enter a valid email address"
                                v-model.trim="item.email"
                                v-validate="'required|email'"
                                data-vv-as="Email"
                                :class="{ 'is-invalid': isInvalid('email') }"
                                :disabled="isLoading"
                                aria-describedby="email-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="email-input-live-feedback">
                    {{ getError('email') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="username-input-group"
                              label="Username"
                              label-for="username-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="username-input"
                                type="text"
                                name="username"
                                placeholder="Username (at least 2 characters)"
                                v-model.trim="item.username"
                                v-validate="'required|min:2'"
                                data-vv-as="Userame"
                                :class="{ 'is-invalid': isInvalid('username') }"
                                :disabled="isLoading"
                                aria-describedby="username-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="username-input-live-feedback">
                    {{ getError('username') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="password-input-group"
                              label="Password"
                              label-for="password-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="password-input"
                                type="password"
                                name="password"
                                placeholder="Password (at least 12 characters)"
                                v-model.trim="item.password"
                                v-validate="'required|min:12'"
                                data-vv-as="Password"
                                :class="{ 'is-invalid': isInvalid('password') }"
                                :disabled="isLoading"
                                ref="password"
                                aria-describedby="passowrd-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="password-input-live-feedback">
                    {{ getError('password') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="confirm-password-input-group"
                              label="Confirm Password"
                              label-for="confirm-password-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <b-form-input id="confirm-password-input"
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm password (must match)"
                                v-model.trim="item.confirmPassword"
                                v-validate="'required|confirmed:password'"
                                data-vv-as="Confirm Password"
                                :class="{ 'is-invalid': isInvalid('confirmPassword') }"
                                :disabled="isLoading"
                                aria-describedby="confirm-password-input-live-feedback">
                  </b-form-input>
                  <b-form-invalid-feedback id="confirm-password-input-live-feedback">
                    {{ getError('confirmPassword') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group id="receive-newsletter-input-group"
                              label="Receive Newsletter"
                              label-for="confirm-password-input"
                              label-class="font-weight-bold"
                              label-cols-sm="5"
                              label-cols-lg="4">
                  <toggle-button v-model="item.receivesNewsletter"
                                 color="#00c8f8"
                                 :sync="true"
                                 :labels="true"/>
                </b-form-group>
                <b-form-group id="recaptcha-input-group">
                  <vue-recaptcha ref="recaptcha"
                                 @verify="onCaptchaVerified"
                                 @expired="onCaptchaExpired"
                                 :sitekey="siteKey">
                  </vue-recaptcha>
                </b-form-group>

                <p class="message-holder" v-show="error">{{ error }}</p>

                <b-form-group id="sign-up-input-group">
                  <b-checkbox class="justify-center" v-model="privacyChk">Agree to our Terms of Use and Privacy Policy
                    <br><span class="message-holder" v-show="errorPrivacy">You must agree to our Terms of Use and Privacy Policy to Sign Up</span>
                  </b-checkbox>
                  <br>
                  <b-button type="submit"
                            block
                            class="JoinCitSci"
                            variant="primary">
                    <font-awesome-icon icon="spinner" pulse v-show="isLoading"/>
                    Sign Up
                  </b-button>
                </b-form-group>
                <!--                  <em>Click to agree to our-->
                <!--                    <router-link to="/privacy-policy" target="_blank">privacy policy</router-link>-->
                <!--                    and our-->
                <!--                    <router-link to="/data-use-policy" target="_blank">terms of use</router-link>-->
                <!--                    .</em>-->

              </b-form-group>
            </b-form>
          </b-card-body>

          <!--            <div v-if="false" class="mt-3 text-center text-muted">-->
          <!--              <hr>-->
          <!--              <b-button size="sm" class="p-0 dropdown-toggle text-right" @click="collapseSocial = !collapseSocial"-->
          <!--                        variant="secondary">-->
          <!--                <font-awesome-icon icon="question"/>-->
          <!--              </b-button>-->
          <!--              &nbsp;&nbsp;&nbsp;&nbsp;-->
          <!--              <b-button size="md" class="mt-1 mb-2 scistarterButton"-->
          <!--                        @click="onClickSignInWithSciStarter"-->
          <!--                        title="Sign up with your SciStarter account"-->
          <!--                        alt="Sign up with your SciStarter account"-->
          <!--                        style="">-->
          <!--                <span><b-img src='/assets/images/SciStarter_v0.png'/></span>-->
          <!--                <span style="color: white; font-size: 1.0rem;">Sign Up with SciStarter</span>-->
          <!--              </b-button>-->
          <!--              &nbsp;&nbsp;-->
          <!--              <b-button size="md" class="mt-1 mb-2 googleButton"-->
          <!--                        @click="onClickSignInWithGoogle"-->
          <!--                        title="Sign up with your Google Account"-->
          <!--                        alt="Sign up with your Google Account">-->
          <!--                <span style="color: white;"><i class="fab fa-google"></i></span>-->
          <!--                <span style="color: white; font-size: 1.0rem;">&nbsp;&nbsp;Sign Up with Google&nbsp;&nbsp;</span>-->
          <!--              </b-button>-->
          <!--              <br>-->
          <!--              <b-row class="justify-content-lg-center">-->
          <!--                <b-card class="col-lg-11" v-show="!collapseSocial" border-variant="secondary" bg-variant="light">-->
          <!--                  <h5>How does this work?</h5>-->
          <!--                  <p class="ml-4" style="text-align: left; font-size: 1.0rem;">-->
          <!--                    1. You Log In & Authorize at the selected provider's website.<br>-->
          <!--                    2. We securely retrieve your Email Address, First & Last names.<br>-->
          <!--                    3. We Sign you Up with this information automatically!<br>-->
          <!--                  </p>-->
          <!--                </b-card>-->
          <!--              </b-row>-->
          <!--            </div>-->

        </b-card>
      </b-col>
    </b-row>

    <b-modal id="privacy-terms"
             ref="privacyTerms"
             title="Review our Terms of Use & Privacy Policy"
             size="xl"
             no-fade
             hide-header-close
             no-close-on-backdrop
             no-close-on-esc
             no-stacking
             centered>
      <Terms></Terms>
      <Privacy></Privacy>
      <template slot="modal-footer">
        <b-btn variant="secondary" class="mr-auto" @click="() => { onClose(false) }">Decline</b-btn>
        <b-btn variant="primary" class="float-right" @click="() => { onClose(true) }">Accept</b-btn>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import {RECAPTCHA_SITE_KEY} from '../../config/_entrypoint';
import Privacy from '../privacy/Show.vue';
import Terms from '../terms/Show.vue';
// import { googleOIDC, sciStarterOIDC } from "../../../main"
export default {
  components: {
    'vue-recaptcha': VueRecaptcha,
    Privacy,
    Terms,
  },

  data() {
    return {
      item: {
        privacySetting: 2,
        receivesNewsletter: true,
        recaptchaToken: '',
        isAgreedPrivacy: false,
      },
      privacyChk: false,
      errorPrivacy: false,
      siteKey: RECAPTCHA_SITE_KEY,
      collapseSocial: true
    }
  },

  computed: {
    ...mapGetters({
      error: 'user/register/error',
      isLoading: 'user/register/isLoading',
      created: 'user/register/created',
      violations: 'user/register/violations',
      isAuthenticated: 'user/auth/isAuthenticated',
      hasSession: 'user/profile/hasSession',
      isLoadingSession: 'user/profile/isLoadingSession',
    }),

    isFilled() {
      let ret =
        (this.item.firstName !== '')
        &&
        (this.item.lastName !== '')
        &&
        (this.item.email !== '')
        &&
        (this.item.username !== '')
        &&
        (this.item.password !== '')
        &&
        (this.item.confirmPassword !== '')
        &&
        (this.item.recaptchaToken !== '')
        && (this.item.isAgreedPrivacy === true)
        && (this.errors.items.length === 0)
      return ret
    }
  },

  watch: {
    hasSession: function (hasSession) {
      if (this.isAuthenticated === false || hasSession === false) {
        return
      }
      let redirectPath = this.$route.query && this.$route.query.redirect || ''
      if (redirectPath.length > 0) {
        this.$router.push({path: redirectPath})
        return
      }
      this.$router.push({name: 'UserProfile'})
    },
    privacyChk(newVal) {
      if (newVal) {
        //Show popup
        this.errorPrivacy = false
        this.$bvModal.show('privacy-terms')
        this.item.isAgreedPrivacy = true
      }
    }
  },

  created() {
    this.reset();
    this.$validator.validateAll();
    let redirectPath = this.$route.query.redirect || '';
    let token = redirectPath.substring(redirectPath.lastIndexOf('/') + 1) || '';

    if (token.length > 0) {
      let decodedToken = null;

      try {
        decodedToken = this.$jwt.decode(token)
      } catch (e) {
        return
      }

      if (decodedToken.exp * 1000 < new Date().getTime()) {
        return
      }

      this.item.email = decodedToken && decodedToken.code || '';
      this.item.firstName = decodedToken && decodedToken.data.firstName || '';
      this.item.lastName = decodedToken && decodedToken.data.lastName || '';
    }
  },

  methods: {
    ...mapActions({
      register: 'user/register/register',
      authenticate: 'user/auth/authenticate',
      retrieveUser: 'user/profile/fetchSession',
      reset: 'user/register/reset'
    }),

    onRegister() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.register(this.item)
            .then((creds) => {
              this.clearRecaptchaReferences()
              if (creds) {
                if (this.$route.query.redirect) {
                  // accept invite / other redirects if present
                  this.$router.push({path: this.$route.query.redirect});
                } else {
                  // Redirect to signup success + email verify page
                  this.$router.push({name: 'VerifyEmail'})
                }
              }
            })
            .catch(error => {
              this.clearRecaptchaReferences()
            })
        } else {
          this.clearRecaptchaReferences()
        }
      })
    },

    onCaptchaVerified(token) {
      this.item.recaptchaToken = token
    },

    onCaptchaExpired() {
      this.clearRecaptchaReferences()
    },

    clearRecaptchaReferences() {
      this.item.recaptchaToken = ''
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset()
      }
    },

    isInvalid(key) {
      return (this.violations !== null && Object.keys(this.violations).length > 0 && this.violations[key]) || this.errors.has(key)
    },

    getError(key) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      if (this.violations && Object.keys(this.violations).length > 0 && this.violations[key]) {
        return this.violations.key
      }
    },

    onClose(bAccept) {
      if (!bAccept) {
        this.item.isAgreedPrivacy = bAccept
        if (this.privacyChk) {
          // Show validation error
          this.errorPrivacy = true
        }
        this.privacyChk = bAccept
      }
      this.$refs.privacyTerms.hide()
    },

    // Handle sign in with scistarter
    // onClickSignInWithSciStarter () {
    //   sciStarterOIDC.clearStaleState()
    //   sciStarterOIDC.createSigninRequest({ state: { bar: 15 } }).then(function(req) {
    //     console.log("scistarter signin request", req)
    //     window.location = req.url
    //   }).catch(function(err) {
    //     console.log(err)
    //   });
    // },
    //
    // // Handle sign in with google
    // onClickSignInWithGoogle () {
    //   googleOIDC.clearStaleState()
    //   googleOIDC.createSigninRequest({ state: { bar: 15 } }).then(function(req) {
    //     console.log("google signin request", req)
    //     window.location = req.url
    //   }).catch(function(err) {
    //     console.log(err)
    //   });
    // },

  }
};
</script>

<style lang="scss" scoped>

#recaptcha-input-group {
  width: 304px;
  margin-left: auto;
  margin-right: auto;
}

.scistarterButton {
  width: 40%;
  background-color: #F67D20;
  border: solid 1px #F67D20;
}

.scistarterButton:hover {
  width: 40%;
  background-color: #DA6409;
  border: solid 1px #DA6409;
}

.googleButton {
  width: 40%;
  background-color: #4285F4;
  border: solid 1px #4285F4;
}

.googleButton:hover {
  width: 40%;
  background-color: #0D5EE1;
  border: solid 1px #0D5EE1;
}

input::-webkit-input-placeholder {
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input:-moz-placeholder { /* Firefox 18- */
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input::-moz-placeholder { /* Firefox 19+ */
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input:-ms-input-placeholder {
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #00c8f8 !important;
  border-color: #00c8f8 !important; /*00AAEF 00AAEF 00c8f8*/
  opacity: 1.00 !important;
}

.btn-primary.disabled:hover, .btn-primary:disabled:hover {
  color: #fff;
  background-color: #879649 !important;
  border-color: #879649 !important;
  opacity: 1.00 !important;
}

.JoinCitSci {
  width: 160px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  font-weight: bold !important;
}

.container-height {
  min-height: 600px;
  overflow-y: auto;
}

.card-body {
  background-color: #F5F4E8 !important;
}
</style>
