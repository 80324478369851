import SubmissionError from '../error/SubmissionError'
import { API_HOST, API_PATH } from '../config/_entrypoint'
import router from '../router/index.js'

const jsonLdMimeType = 'application/ld+json'

export default function (url, options = {}) {
  if (typeof options.headers === 'undefined') Object.assign(options, { headers: new Headers() })

  if (options.headers.get('Accept') === null) options.headers.set('Accept', jsonLdMimeType)

  if (options.body !== undefined && !(options.body instanceof FormData) && options.headers.get('Content-Type') === null) {
    options.headers.set('Content-Type', jsonLdMimeType)
    if (options.method === 'PATCH') {
      console.log('setting patch header')
      options.headers.set('Content-Type', 'application/merge-patch+json')
    }
  }

  if (options.headers.get('Authorization') === null && window.localStorage.getItem('tokenBearer')) {
    // TODO: Check if the AccessToken is about to expire and renew it
    // Skip adding bearer when refreshing token
    if (url !== '/token/refresh') {
      options.headers.set('Authorization', 'Bearer ' + window.localStorage.getItem('tokenBearer'))
    }
  }

  const link = url.includes(API_PATH) ? API_HOST + url : API_HOST + API_PATH + url

  return fetch(link, options).then((response) => {
    if (response.ok) return response
    else if (options.method === 'GET' && response.status === 404) router.push({ name: "NotFound"})
    // else if (options.method === 'GET' && (response.status === 403 || response.status === 401)) return response
    // Logout user's with 'username' token
    if (response.status === 401) {
      response.clone().json().then(error => {
        console.log('401 Auth Error ', error['message'])
        if (error.message === 'Unable to find key "id" in the token payload.') {
            router.push({name: "LogoutEmailLogin"})
        }
    })
   }

    return response
      .json()
      .then((json) => {
        let error = ''
        if (json['hydra:description']) {
          error = json['hydra:description']
        } else if (json['message']) {
          // necessary to reset password coming from old DB
          if (json['resetPassword']) {
            throw json
          }
          error = json['message']
        } else {
          error = response.statusText
        }

        if (!json.violations) throw Error(error)

        const errors = { _error: error }
        json.violations.map(violation =>
          Object.assign(errors, { [violation.propertyPath]: violation.message }))

        throw new SubmissionError(errors)
      })
  })
}
