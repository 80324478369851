<template lang="html">
<div class="">
    <div class="row my-3" v-if="is1AlreadyMember || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-check fa-3x mr-3 text-success"></i>
            </div>
            <div class="font-weight-bold flex-none">
                Your account is already a member of {{ project.name }}. You do not
                need an invitation to access it. You may add data or explore the
                project using the navigation above.
            </div>
        </div>
    </div>
    <div class="row my-3" v-else-if="is2TokenBad || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-close fa-3x mr-3 text-danger"></i>
            </div>
            <div class="font-weight-bold flex-none">
                There was an error reading the invitation. Please go back to the email
                and try the link again. This was mostly likely caused by not copying
                the full URL.
            </div>
        </div>
    </div>
    <div class="row my-3" v-else-if="is3NotLoggedIn || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-exclamation-triangle fa-3x mr-3 text-warning"></i>
            </div>
            <div class="font-weight-bold flex-none">
                You are not logged in. You must be logged in with the email "{{
            tokenEmail
          }}" to accept this invitation. You may either:
            </div>
        </div>
        <div class="col-md-4 py-3" v-if="project.approveContacts">
            A. Create an account with the email "{{ tokenEmail }}". You will automatically accept the invitation.
            <router-link :to="{ name: 'Register', query: { redirect: this.$route.fullPath } }" class="btn btn-tertiary btn-block mt-2">
                Create Account</router-link>
        </div>
        <div class="col-md-4 py-3">
            B. If you already have an account with the email "{{ tokenEmail }}".
            Click the login button below. You will automaticly accept the
            invitation.
            <router-link :to="{ name: 'Login', query: { redirect: this.$route.fullPath } }" class="btn btn-tertiary btn-block mt-2">Login</router-link>
        </div>
    </div>
    <div class="row my-3" v-else-if="is4TokenExpired || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-exclamation-triangle fa-3x mr-3 text-warning"></i>
            </div>
            <div class="font-weight-bold flex-none">
                Your invitation expired {{ tokenExpires }}. Invitations are valid for
                30 days.
            </div>
        </div>
        <div class="col-md-4 py-3">
            Please submit a request to join {{ project.name }}. Click the button
            below.
            <span v-if="project.approveContacts">Your request will be reviewed by a project manager.</span>
            <ProjectJoinButton :project="project"></ProjectJoinButton>
        </div>
    </div>
    <div class="row my-3" v-else-if="is5WrongEmail || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-exclamation-triangle fa-3x mr-3 text-warning"></i>
            </div>
            <div class="font-weight-bold flex-none">
                This invitation is only for the email "{{ tokenEmail }}". You are
                logged in with the email "{{ userEmail }}". You may:
            </div>
        </div>
        <div class="col-md-4 py-3">
            A. Join the project using this account. Click the button below.
            <span v-if="project.approveContacts">Your request will be reviewed by a project manager.</span>
            <ProjectJoinButton :project="project"></ProjectJoinButton>
        </div>
        <div class="col-md-4 py-3">
            B. Logout of this account. Then login or create an account using the email "{{
          tokenEmail
        }}".
            <router-link :to="{ name: 'Logout', query: { redirect: this.$route.fullPath } }" class="btn btn-tertiary btn-block mt-2">
                Logout</router-link>
        </div>
    </div>
    <div class="row my-3" v-else-if="is6SubmittingRequest || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-spin fa-spinner fa-3x mr-3"></i>
            </div>
            <div class="font-weight-bold flex-none">
                Accepting your invitation...
            </div>
        </div>
    </div>
    <div class="row my-3" v-else-if="is7ServerError || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-close fa-3x mr-3 text-danger"></i>
            </div>
            <div class="font-weight-bold flex-none">
                There was a server error. To fix this you may:
            </div>
        </div>
        <div class="col-md-4 py-3">
            A. Submit a request to join {{ project.name }}. Click the button
            below.
            <span v-if="project.approveContacts">Your request will be reviewed by a project manager.</span>
            <ProjectJoinButton :project="project"></ProjectJoinButton>
        </div>
        <div class="col-md-4 py-3">
            B. If you would like to submit a bug report, please click the link below.
            <a target="_blank" :href="
            `https://docs.google.com/forms/d/e/1FAIpQLSc_K0CvacZRItpra4_O5npwjZZTYzAPHPidUxr4rVrDUf9EVg/viewform?usp=pp_url&entry.630403450=https://citsci.org${$route.fullPath}`
          " class="btn btn-info btn-block mt-2">
                <span class="mr-2">Submit Bug Report</span><i class="fa fa-question"></i>
            </a>
        </div>
        <div class='col-md-4 py-3' v-if='serverErrorJoin'>{{serverErrorJoin}}</div>
    </div>
    <div class="row my-3" v-else-if="is8HasJoined || test">
        <div class="col-12 d-flex align-items-center">
            <div class="flex-none">
                <i class="fa fa-check fa-3x mr-3 text-success"></i>
            </div>
            <div class="font-weight-bold flex-none">
                You have accepted the invitation to {{ project.name }}. Welcome to the project! You may now add data or explore the project using the
                navigation above. This is one of many projects hosted on the CitSci.org platform.
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";

import ProjectJoinButton from "../../../../assets/scripts/project/projectJoinButton.vue";

export default {
    components: {
        ProjectJoinButton
    },
    data() {
        return {
            // order to check, first true determines dialog
            is1AlreadyMember: null,
            is2TokenBad: null,
            is3NotLoggedIn: null,
            is4TokenExpired: null,
            is5WrongEmail: null,
            is6SubmittingRequest: null,
            is7ServerError: null,
            is8HasJoined: null,

            tokenDecoded: null,
            tokenExpires: moment(1).format("ddd MMMM DD, YYYY h:mm:ss a"),
            tokenEmail: null,
            userEmail: null,
            serverError: null,

            test: false, // helped to find logic errors
        };
    },
    created() {
        this.acceptInvitePrecheck();
    },

    computed: {
        ...mapGetters({
            project: "project/get/project",
            hasSession: "user/profile/hasSession",
            membershipMap: "user/profile/membershipMap",
            userProfile: "user/profile/sessionData",
            joinIsLoading: "project/show/isLoading",
            serverErrorJoin: "project/show/error"
        })
    },
    methods: {
        ...mapActions({
            joinToProject: "project/show/joinToProject"
        }),
        acceptInvitePrecheck() {

            if (this.hasSession) {
                this.userEmail = this.userProfile.email;
            }
            if (
                this.hasSession &&
                this.membershipMap[this.project.id] &&
                this.membershipMap[this.project.id].isMember
            ) {
                this.is1AlreadyMember = true;
            } else {
                this.is1AlreadyMember = false;
            }
            this.token = this.$route.params.token;
            console.log('Project Invite: ', this.token)
            try {
                this.tokenDecoded = this.$jwt.decode(this.token);
            } catch (e) {
                console.log(e);
                this.is2TokenBad = true;
            }
            if (
                this.tokenDecoded === null ||
                this.tokenDecoded.exp === undefined ||
                this.tokenDecoded.code === undefined
            ) {
                this.is2TokenBad = true;
            } else {
                this.tokenExpires = moment(this.tokenDecoded.exp * 1000).format(
                    "ddd MMMM DD, YYYY h:mm:ss a"
                );
                this.tokenEmail = this.tokenDecoded.code;
                this.is2TokenBad = false;
            }
            if (!this.hasSession) {
                this.is3NotLoggedIn = true;
                // uncomment to initate auto redirect
                // this.$router.push({name: 'Register', query: { redirect: this.$route.fullPath }})
            } else {
                this.is3NotLoggedIn = false;
            }
            if (
                this.tokenDecoded === null ||
                this.tokenDecoded.exp === undefined ||
                this.tokenDecoded.exp * 1000 < new Date().getTime()
            ) {
                this.is4TokenExpired = true;
            } else {
                this.is4TokenExpired = false;
            }
            if (
                this.tokenDecoded === null ||
                this.tokenDecoded.code === undefined ||
                this.userProfile === null ||
                this.userProfile.email === undefined ||
                this.tokenDecoded.code !== this.userProfile.email
            ) {
                this.is5WrongEmail = true;
            } else {
                this.is5WrongEmail = false;
            }
            if (
                !this.is1AlreadyMember &&
                !this.is2TokenBad &&
                !this.is3NotLoggedIn &&
                !this.is4TokenExpired &&
                !this.is5WrongEmail
            ) {
                this.acceptInviteSend();
            }
            return;
        },

        acceptInviteSend() {
            this.is6SubmittingRequest = true;
            this.joinToProject({
                id: this.project.id,
                data: {
                    token: this.token
                }
            }).then((data) => {
                this.is6SubmittingRequest = false;
                this.is7ServerError = false
                this.serverError = ''
                this.is8HasJoined = true;
                // setTimeout(() => this.$router.push({
                //     name: 'ProjectShow',
                //     params: {
                //         id: this.projectId
                //     }
                // }), 5000)
                // return
            })
        }
    },
    watch: {
        joinIsLoading(status) {
            if (!status) {
                this.is6SubmittingRequest = false
            }
        },
        serverErrorJoin(newError) {
            this.is7ServerError = true
        },
        hasSession(status) {
            if (status === true) {
                this.acceptInvitePrecheck();
            }
        },
    },
};
</script>

<style lang=""></style>
