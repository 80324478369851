<template>
  <b-container style="height:75vh;">
    <b-row class="justify-content-md-center">
      <b-col col lg="5">
        <h1>Your New Password</h1>
        <b-card bg-variant="light" style="background-color:#F5F4E8 !important;">
          <div v-if="expiredToken">Your password reset code has expired. Please try again.</div>
          <div v-if="invalidToken">Your password reset code is incorrect. Please check your email for the link and try
            again.
          </div>
          <b-form v-if="!expiredToken && !invalidToken" @submit.prevent="onPasswordUpdate" novalidate>
            <b-form-group class="mb-0">
              <!--              <b-form-group id="new-password-input-group"-->
              <!--                            label="New Password (12 characters or more)"-->
              <!--                            label-for="email-input">-->
              <!--                <b-form-input id="new-password-input"-->
              <!--                              type="password"-->
              <!--                              name="password"-->
              <!--                              placeholder="New Password"-->
              <!--                              v-model.trim="item.password"-->
              <!--                              v-validate="'required|min:12'"-->
              <!--                              :class="{ 'is-invalid': isInvalid('password') }"-->
              <!--                              :disabled="isLoading || expiredToken || invalidToken"-->
              <!--                              aria-describedby="new-password-input-live-feedback">-->
              <!--                </b-form-input>-->
              <!--                <b-form-invalid-feedback id="new-password-input-live-feedback">-->
              <!--                  {{ getError('password') }}-->
              <!--                </b-form-invalid-feedback>-->
              <!--              </b-form-group>-->
              <b-form-group id="password-input-group"
                            label="New Password"
                            label-for="password-input"
                            label-class="font-weight-bold"
                            label-cols-sm="5"
                            label-cols-lg="4">
                <b-form-input id="password-input"
                              type="password"
                              name="password"
                              placeholder="New Password (at least 12 characters)"
                              v-model.trim="item.password"
                              v-validate="'required|min:12'"
                              data-vv-as="Password"
                              :class="{ 'is-invalid': isInvalid('password') }"
                              :disabled="isLoading || expiredToken || invalidToken"
                              ref="password"
                              aria-describedby="password-input-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="password-input-live-feedback">
                  {{ getError('password') }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="confirm-password-input-group"
                            label="Confirm New Password"
                            label-for="confirm-password-input"
                            label-class="font-weight-bold"
                            label-cols-sm="5"
                            label-cols-lg="4">
                <b-form-input id="confirm-password-input"
                              type="password"
                              name="confirmPassword"
                              placeholder="Confirm new password (must match)"
                              v-model.trim="item.confirmPassword"
                              v-validate="'required|confirmed:password'"
                              data-vv-as="Password"
                              :class="{ 'is-invalid': isInvalid('confirmPassword') }"
                              :disabled="isLoading || expiredToken || invalidToken"
                              aria-describedby="confirm-password-input-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="confirm-password-input-live-feedback">
                  {{ getError('confirmPassword') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <p class="message-holder" v-show="error">{{ error }}</p>

              <b-button type="submit"
                        block
                        variant="primary"
                        :disabled="isLoading || expiredToken || invalidToken">
                <font-awesome-icon icon="spinner" pulse v-show="isLoading"/>
                Update My Password
              </b-button>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {createNamespacedHelpers} from 'vuex'

const {mapGetters, mapActions} = createNamespacedHelpers('user/resetPassword')

export default {
  data() {
    return {
      item: {}
    }
  },

  created() {
    this.checkToken(decodeURIComponent(this.$route.params.token))
    this.$validator.validateAll()
  },

  computed: {
    ...mapGetters([
      'error',
      'isLoading',
      'violations',
      'updated',
      'expiredToken',
      'invalidToken',
    ]),

    isFilled() {
      return this.item.password && !this.errors.items.length
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return
      }

      this.$router.push({name: 'Login'})
    }
  },

  methods: {
    ...mapActions([
      'update',
      'checkToken',
    ]),

    onPasswordUpdate() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.update({
            item: this.item,
            resetToken: decodeURIComponent(this.$route.params.token)
          })
        }
      })
    },

    isInvalid(key) {
      return (this.violations !== null && Object.keys(this.violations).length > 0 && this.violations[key]) || this.errors.has(key)
    },

    getError(key) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      if (this.violations && Object.keys(this.violations).length > 0 && this.violations[key]) {
        return this.violations.key
      }
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
