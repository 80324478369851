import fetch from '../../../utils/fetch'

const initialState = {
  isLoading: false,
  error: '',
  created: null,
  userHistory: null,
  projectHistory: null,
}

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  created(state) {
    return state.created
  },
  userHistory(state) {
    return state.userHistory
  },
  projectHistory(state) {
    return state.projectHistory
  },
  error(state) {
    return state.error
  },
}

const actions = {

  createHistory({commit, rootState}, data) {
    commit(types.AIRTABLE_HISTORY_ERROR, '')
    commit(types.AIRTABLE_HISTORY_LOADING)

    data.user = rootState.user.profile.sessionData['@id'];
    data.project = rootState.project.get.project['@id'];
    return fetch('/project_airtable_history', {method: 'POST', body: JSON.stringify(data)})
      .then(response => response.json())
      .then((data) => {
        commit(types.AIRTABLE_HISTORY_CREATED, data)
        return data
      })
      .catch((error) => {
        commit(types.AIRTABLE_HISTORY_ERROR, error)
      })
  },

  getUserHistory({commit}, data) {
    commit(types.AIRTABLE_HISTORY_ERROR, '')
    commit(types.AIRTABLE_HISTORY_LOADING)

    // + '&project='+data.projectId
    return fetch('/users/' + data.userId + '/project_airtable_history' + data.urlParams)
      .then(response => response.json())
      .then((data) => {
        commit(types.AIRTABLE_USER_HISTORY, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.AIRTABLE_HISTORY_ERROR, error)
      })
  },

  getProjectHistory({commit}, data) {
    commit(types.AIRTABLE_HISTORY_ERROR, '')
    commit(types.AIRTABLE_HISTORY_LOADING)

    return fetch('/users/' + data.userId + '/project_airtable_history' + data.urlParams + '&project.id=' + data.projectId)
      .then(response => response.json())
      .then((data) => {
        commit(types.AIRTABLE_PROJECT_HISTORY, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.AIRTABLE_HISTORY_ERROR, error)
      })
  },
}


const types = {
  AIRTABLE_HISTORY_LOADING: 'AIRTABLE_HISTORY_LOADING',
  AIRTABLE_HISTORY_ERROR: 'AIRTABLE_HISTORY_ERROR',
  AIRTABLE_HISTORY_CREATED: 'AIRTABLE_HISTORY_CREATED',
  AIRTABLE_USER_HISTORY: 'AIRTABLE_USER_HISTORY',
  AIRTABLE_PROJECT_HISTORY: 'AIRTABLE_PROJECT_HISTORY',
  RESET: 'RESET',
}

const mutations = {
  [types.AIRTABLE_HISTORY_LOADING](state) {
    state.isLoading = true;
  },
  [types.AIRTABLE_HISTORY_CREATED](state, data) {
    state.created = data
    state.isLoading = false
  },
  [types.AIRTABLE_HISTORY_ERROR](state, error) {
    state.errors = error;
    state.isLoading = false;
  },
  [types.AIRTABLE_USER_HISTORY](state, data) {
    state.userHistory = data
    state.isLoading = false
  },
  [types.AIRTABLE_PROJECT_HISTORY](state, data) {
    state.projectHistory = data
    state.isLoading = false
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}

