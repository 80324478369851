<template>
  <b-container class="h-75 container-height">
    <h1>Log In</h1>
    <b-row class="justify-content-md-center">
      <b-col col lg="4" md="8" sm="12">
        <b-card bg-variant="light">
          <b-form @submit.prevent="onClickLogin" novalidate>
            <b-form-group class="mb-0">
              <b-form-group id="email-input-group"
                            label="Email"
                            label-for="email-input"
                            label-class="font-weight-bold">
                <b-form-input id="email-input"
                              type="email"
                              name="email"
                              placeholder="Registered Email"
                              size="lg"
                              v-model.trim="creds.email"
                              v-validate="'required|email'"
                              :class="{ 'is-invalid': isInvalid('email') }"
                              :disabled="isLoading || isLoadingSession"
                              aria-describedby="email-input-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="email-input-live-feedback">
                  {{ getError('email') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group id="password-input-group"
                            label="Password"
                            label-for="password-input"
                            label-class="font-weight-bold"
                            class="mt-4">
                <b-form-input id="password-input"
                              type="password"
                              name="password"
                              placeholder="Password"
                              size="lg"
                              v-model.trim="creds.password"
                              v-validate="'required'"
                              :class="{ 'is-invalid': isInvalid('password') }"
                              :disabled="isLoading || isLoadingSession"
                              aria-describedby="password-input-live-feedback">
                </b-form-input>
                <b-form-invalid-feedback id="password-input-live-feedback">
                  {{ getError('password') }}
                </b-form-invalid-feedback>
                <div class="mt-2">
                  <router-link :to="{ name: 'ForgotPassword' }">Forgot password?</router-link>
                </div>
              </b-form-group>

              <p class="message-holder" v-show="error && !passwordReset">{{ error }}</p>

              <b-button type="submit"
                        block
                        variant="primary"
                        alt="Log In"
                        title="Log In"
                        size="lg"
              >
                <font-awesome-icon icon="spinner" pulse v-show="isLoading || isLoadingSession"/>
                Log In
              </b-button>
            </b-form-group>
          </b-form>

          <div v-if="false" class="mt-4 text-center text-muted">
            <hr>
            <b-button size="md" class="mt-1 mb-2 scistarterButton"
                      @click="onClickSignInWithSciStarter"
                      title="Log into CitSci with your SciStarter account"
                      alt="Log into CitSci with your SciStarter account"
                      style="">
              <span><b-img src='/assets/images/SciStarter_v0.png'/></span>
              <span style="color: white; font-size: 1.0rem;">Continue with SciStarter</span>
            </b-button>
            <b-button size="md" class="mt-1 mb-2 googleButton"
                      @click="onClickSignInWithGoogle"
                      title="Log into CitSci with your Google Account"
                      alt="Log into CitSci with your Google Account">
              <span style="color: white;"><i class="fab fa-google"></i></span>
              <span style="color: white; font-size: 1.0rem;">&nbsp;&nbsp;Continue with Google&nbsp;&nbsp;</span>
            </b-button>
          </div>
        </b-card>
      </b-col>

    </b-row>
    <b-row class="mb-3 justify-content-lg-center">
      <b-col cols="12" class="text-center">
        <div class="mt-4 mb-4">
          <p>New to CitSci?</p>
          <b-btn id="signUpButton" size="lg" class="ml-1" variant="tertiary" :to="{ name: 'Register' }">Sign Up</b-btn>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
// import { googleOIDC, sciStarterOIDC } from "../../../main";

export default {
  data() {
    return {
      creds: {}
    };
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.$validator.validateAll();
  },

  mounted() {
  },

  computed: {
    ...mapGetters({
      isLoading: "user/auth/isLoading",
      isAuthenticated: "user/auth/isAuthenticated",
      error: "user/auth/error",
      violations: "user/auth/violations",
      passwordReset: "user/auth/passwordReset",
      isLoadingSession: "user/profile/isLoadingSession",
      hasSession: "user/profile/hasSession",
    }),

    isFilled() {
      return (
        this.creds.email && this.creds.password && !this.errors.items.length
      );
    }
  },

  watch: {
    hasSession() {
      this.redirectOnLogin();
    },
    isAuthenticated() {
      this.redirectOnLogin();
    },
    passwordReset(newVal) {
      if (!newVal) {
        return;
      }
      this.$router.push({name: "UpdatePassword"});
    }
  },

  methods: {
    ...mapActions({
      authenticate: "user/auth/authenticate",
      forgotReset: "user/forgotPassword/reset",
      resetPasswordReset: "user/resetPassword/reset",
      activateReset: "user/activate/reset"
    }),

    redirectOnLogin() {
      if ((this.hasSession === true) && (this.isAuthenticated === true)) {
        if (
          !this.$route.query.redirect ||
          this.$route.query.redirect.indexOf("logout") !== -1 ||
          this.$route.query.redirect.indexOf("/404") !== -1
        ) {
          this.$router.push({name: "UserProfile"});
        } else {
          this.$router.push({path: this.$route.query.redirect});
        }
      }
    },
    // Handle sign in with scistarter
    // onClickSignInWithSciStarter() {
    //   sciStarterOIDC.clearStaleState();
    //   sciStarterOIDC
    //     .createSigninRequest({ state: { bar: 15 } })
    //     .then(function(req) {
    //       // console.log("scistarter signin request", req)
    //       window.location = req.url;
    //     })
    //     .catch(function(err) {
    //       console.log(err);
    //     });
    // },
    //
    // // Handle sign in with google
    // onClickSignInWithGoogle() {
    //   googleOIDC.clearStaleState();
    //   googleOIDC
    //     .createSigninRequest({ state: { bar: 15 } })
    //     .then(function(req) {
    //       // console.log("google signin request", req)
    //       window.location = req.url;
    //     })
    //     .catch(function(err) {
    //       console.log(err);
    //     });
    // },

    onClickLogin() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.authenticate({creds: this.creds});
        }
      });
    },

    isInvalid(key) {
      return (
        (this.violations !== null &&
          Object.keys(this.violations).length > 0 &&
          this.violations[key]) ||
        this.errors.has(key)
      );
    },

    getError(key) {
      if (this.errors.has(key)) {
        return this.errors.first(key);
      }

      if (
        this.violations &&
        Object.keys(this.violations).length > 0 &&
        this.violations[key]
      ) {
        return this.violations.key;
      }
    },

    reset() {
      this.forgotReset();
      this.resetPasswordReset();
      this.activateReset();
    }
  }
};
</script>

<style lang="scss" scoped>

.scistarterButton {
  width: 85%;
  background-color: #F67D20;
  border: solid 1px #F67D20;
}

.scistarterButton:hover {
  width: 85%;
  background-color: #DA6409;
  border: solid 1px #DA6409;
}

.googleButton {
  width: 85%;
  background-color: #4285F4;
  border: solid 1px #4285F4;
}

.googleButton:hover {
  width: 85%;
  background-color: #0D5EE1;
  border: solid 1px #0D5EE1;
}

.greg {
  color: red;
}

.col-form-label-lg {
  padding-top: 0px;
  padding-bottom: 0px;
  color: red !important;
}

.white {
  color: #fff;
}

input::-webkit-input-placeholder {
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input:-moz-placeholder { /* Firefox 18- */
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input::-moz-placeholder { /* Firefox 19+ */
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

input:-ms-input-placeholder {
  color: #BFBFBF !important;
  font-size: 1.00rem;
}

.container-height {
  min-height: 600px;
}

.card-body {
  background-color: #F5F4E8 !important;
}
</style>
