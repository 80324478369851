import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import {getFileObjectIRI, getFilesIRI} from '../../../../helpers/user_helper'

/**
 * @function getObservation
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getObservation = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id
  let query = data.query || ""

  return fetch('/observations/' + id + query)
    .then(response => response.json())
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_SHOW_SET_RETRIEVED, data)

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_SHOW_SET_ERROR, e.message)
    })
}

/**
 * @function deleteObservation
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const deleteObservation = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id

  return fetch('/observations/' + id, {
    method: 'DELETE',
  })
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)

        return false
      }

      // eslint-disable-next-line
      commit(types.OBSERVATION_SHOW_SET_ERROR, 'We couldn\'t delete the item. Please try again later or contact the administrator.')

      return false
    })
}

/**
 * @function saveObservationRecord
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const saveObservationRecord = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id

  return fetch('/observation_records/' + id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(data.recordData)
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_RECORD_SHOW_SET_UPDATED, data)

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors)
    })
}

/**
 * @function deleteRecord
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const deleteRecord = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id

  return fetch('/observation_records/' + id, {
    method: 'DELETE',
  })
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_SHOW_SET_DELETED, (data === 204))
      return data.status == 204
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)

        return false
      }

      // eslint-disable-next-line
      commit(types.OBSERVATION_SHOW_SET_ERROR, 'We couldn\'t delete the item. Please try again later or contact the administrator.')

      return false
    })
}

/**
 * @function uploadObservationImage
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const uploadObservationImage = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id || ''

  return fetch('/file_objects' + (id.length > 0 ? ('/' + id) : '' ), {
    method: 'POST',
    body: data.fd
  })
    .then((response) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        return
      }

      commit(types.OBSERVATION_SHOW_SET_ERROR, e.message)
      commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
    })
}

/**
 * @function observationUpdate
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const observationUpdate = ({commit}, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

  if (data && data.id && data.id.length === 0) {
    commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
    commit(types.OBSERVATION_SHOW_SET_ERROR, 'Please provide the proper observation ID.')

    return
  }

  return fetch('/observations/' + data.id, {
    method: 'PUT',
    body: JSON.stringify(data.observation)
  })
    .then((response) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.OBSERVATION_SHOW_SET_RETRIEVED, data)
      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        return
      }

      commit(types.OBSERVATION_SHOW_SET_ERROR, e.message)
      commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
    })
}

/**
 * @function updateObservationDate
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const updateObservationDate = ({commit}, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  const payload = {
    "observedAt": data.dateTimeUpdated ,
  };
  return fetch('/observations/' + data.id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(payload)
  })
    .then((response) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.OBSERVATION_SHOW_SET_UPDATED, data)
      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        return
      }
      commit(types.OBSERVATION_SHOW_SET_ERROR, e.message)
      commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
    })
}

/**
 * @function createRecord
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const createRecord = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

  return fetch('/observation_records', {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_RECORD_SHOW_CREATED, data)

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors)
    })
}

export const updateOrganismSubRecord = ({ commit }, data) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
  commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
  let id = data.id
  delete data.id
  let recordObject = data.records



  return fetch('/observation_records/' + id, {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(data)
  })
    .then((data) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)
      commit(types.OBSERVATION_SHOW_SET_DELETED, (data === 204))
      return data.status == 204
    })
    .catch((e) => {
      commit(types.OBSERVATION_SHOW_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.OBSERVATION_UPDATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.OBSERVATION_SHOW_SET_ERROR, e.errors._error)

        return false
      }

      // eslint-disable-next-line
      commit(types.OBSERVATION_SHOW_SET_ERROR, 'We couldn\'t delete the item. Please try again later or contact the administrator.')

      return false
    })
}

/**
 * @function resetError
 * @param commit
 */
export const resetError = ({ commit }) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, '')
}

/**
 * @function setError
 * @param commit
 * @param error
 */
export const setError = ({ commit }, error) => {
  commit(types.OBSERVATION_SHOW_SET_ERROR, error)
}
