<template>
  <div>
    <b-container>
      <b-alert variant="danger" :show="!!error">
        <font-awesome-icon icon="exclamation-triangle"/>
        {{ error }}
      </b-alert>
      <h1 class="pb-0 mb-1 mt-1">Projects</h1>
      <div class="btn-toolbar float-right" style="margin-top:-42px;">
        <b-button class="btn btn-primary" id="AddProjectButton" title="Start A New CitSci Project"
                  alt="Start A New CitSci Project" @click="onCreateClick">
          <i class="fa fa-plus" style="color: #ffffff;"></i><span
          class="label-text">&nbsp;&nbsp;<strong>Start A Project</strong></span>
        </b-button>
      </div>

      <div class="container p-0">
        <h2 class="mb-1 ml-1 mr-1 mt-0 pt-0">Featured Hubs
          <span class="p-down ml-3">
            <router-link :to="{ name: 'Hubs' }" :alt="'Hubs'" :title="'Hubs'">
              <span class="d-none d-md-inline">Learn more & view all</span>
              <i class="ml-1 fas fa-arrow-alt-circle-right" aria-hidden="true"></i>
            </router-link>
          </span>
        </h2>
        <div class="row d-flex align-items-stretch" v-if="!isOrganizationsLoading">
          <div v-for="featuredHub in featuredHubs" :key="featuredHub.id" class="col-md-2 mb-2 mx-0">
            <div class="card p-0 mx-0">
              <div v-if="featuredHub.banner" v-bind:style="{
                'background-image': 'url(' + featuredHub.banner.path + ')',
                'height': '120px',
                'background-color': '#cccccc',
                'background-position': 'center',
                'background-repeat': 'no-repeat',
                'background-size': 'cover'
              }">
              </div>
              <div v-else style="height: 120px; background-color:grey;">&nbsp;</div>
              <router-link :to="{ name: 'OrganizationShow', params: { name: featuredHub.url }}"
                           class="stretched-link" :alt="featuredHub.name + ' Hub'" :title="featuredHub.name + ' Hub'">
              </router-link>
              <div class="card-footer pl-2 pr-0" style="background-color: #F5F4E8">
                <!-- <h5>{{ ellipsizeCharacters(featuredHub.name, 16) }}</h5> -->
                <h5 class="d-none d-md-block">{{ ellipsizeCharacters(featuredHub.name, 16) }}</h5>
                <h5 class="d-md-none">{{ featuredHub.name }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-else>
          <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading Hubs...
        </div>
      </div>

      <div class="card-body pb-0">
        <div class="row">
          <h2 class="mb-1 mt-1 col-md-11">Projects</h2>
<!--          <div class="float-right mt-1 mb-1">-->
<!--            <b-button title="Reset this page to default filtering" @click="resetProjects()">Reset</b-button>-->
<!--          </div>-->
          <div class="card col-md-12" style="background-color: #9DA3A8; border: none;">
            <div class="card-header p-0 pb-2" style="background-color: #9DA3A8; border: none;">
              <b-form class="mt-1" v-on:submit.prevent="submit" action="#">
                <div class="input-group col-sm-3 float-left mt-1 pl-0">
                  <b-input-group-prepend>
                    <span class="btn btn-secondary" title="Start typing to search for citizen science projects">
                      <i v-if='!isBusy' class="fas fa-search"></i>
                      <i v-if='isBusy' class="fas fa-spinner fa-spin"></i>
                    </span>
                  </b-input-group-prepend>
                  <b-form-input class="form-control float-left" placeholder="Search" name="search"
                                v-model="filterText" debounce="2000"></b-form-input>
                  <div class="input-group-append" :style="{visibility: filterText ? 'visible' : 'hidden'}">
                    <b-button :disabled="!filterText" @click="filterText=null"><i
                      class="fas fa-times-circle"></i></b-button>
                  </div>
                </div>
                <div class="input-group col-sm-3 float-left mt-1 pl-0">
                  <b-input-group-prepend>
                    <span class="btn btn-secondary" title="Filter Projects By Hub">
                      <i v-if='!isBusy' class="fas fa-filter"></i>
                      <i v-if='isBusy' class="fas fa-spinner fa-spin"></i>
                    </span>
                  </b-input-group-prepend>
                  <b-form-select v-if="!isOrganizationsLoading" v-model="filterOrg"
                                 class="form-control float-right"
                                 :options="organizationsArray" :option-height="20" label="name"
                                 placeholder="Filter By Hub">
                  </b-form-select>
                  <div class="input-group-append" :style="{visibility: filterOrg ? 'visible' : 'hidden'}">
                    <b-button :disabled="!filterOrg" @click="filterOrg=null"><i
                      class="fas fa-times-circle"></i></b-button>
                  </div>
                </div>
              </b-form>
              <div class="btn-group mr-1 float-right mt-1 pr-0">
                <div class="mr-2 label-text" style="float: left; margin-top: 6px; color:#ffffff;">
                </div>
                <button v-if="false" type="button" class="btn btn-secondary" id="ListViewButton" title="List View">
                  <i class="fa fa-bars"></i></button>
                <button v-if="false" type="button" class="btn btn-secondary" id="GridViewButton" title="Table/Grid View"
                        disabled><i class="fa fa-table"></i></button>
                <button v-if="false" type="button" class="btn btn-secondary" id="CardViewButton" title="Card View"
                        disabled><i class="fa fa-th-large"></i></button>
                <button v-if="false" type="button" class="btn btn-secondary mr-3" id="MapViewButton"
                        title="Map View (coming soon)" disabled>
                  <i class="fa fa-map-marker "></i></button>
                <button type="button"
                        class="btn btn-secondary mr-1"
                        id="MostActivityProjectsButton"
                        title="Sort projects by the most data"
                        @click="sortBy='measurementsTotal'">
                  <i class="fa fa-sort"><span class="ml-2 label-text">Most Data</span></i>
                </button>
                <button type="button"
                        class="btn btn-secondary mr-1"
                        id="NewestProjectsButton"
                        title="Sort projects by most recently started"
                        @click="sortBy='createdAt'">
                  <i class="fa fa-sort"><span class="ml-2 label-text">Newest</span></i>
                </button>
                <button v-if="false"
                        type="button"
                        class="btn btn-secondary"
                        id="ActiveProjectsButton"
                        title="Show Recently Active Projects (coming soon)"
                        disabled>
                  <i class="fa fa-clock"><span class="ml-2 label-text">Recently Active</span></i>
                </button>
                <button v-if="false"
                        type="button"
                        class="btn btn-secondary"
                        id="FeaturedProjectsButton"
                        title="Show Featured Projects (coming soon)"
                        disabled>
                  <i class="fa fa-star-o"><span class="ml-2 label-text">Featured</span></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="my-1 col-md-3">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} projects
          </div>

          <div class="col-md-3 my-1">
            <b-pagination
              v-if="perPage > 10"
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </div>

          <div class="col-md-3 my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </div>

      <b-table class="p-0 mb-2"
        id="projectsTable"
        ref="projectsTable"
        show-empty
        hover
        :sticky-header="true"
        stacked="lg"
        :items="projects"
        :fields="projectsListFields"
        :busy="isBusy"
        :projectState=this.PROJECT_STATE_PUBLISHED
        :current-page="currentPage"
        :per-page="perPage"
        :total-rows="totalItems"
        :filter="filterVal"
        sort-icon-left
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :striped="false"
        :empty-text="emptyText"
        :empty-filtered-text="emptyFilteredText"
        @filtered="onFiltered"
      >
        <template v-slot:cell(photo)="row">
          <router-link
            :to="{ name: 'ProjectShow', params: { id: row.item.urlField }}"
            :id="'project-photo-field-' + row.item['id']" class="d-block photo-link mx-auto">
            <b-row class="mx-auto fit-content">
              <b-col class="mx-0 px-0 overflow-hidden" style="max-height: 68px; max-width: 100px;">
                <!-- fit-content -->
                <b-img class="mx-0 h-100" rounded="0"
                       :src="row.item.picture ? row.item.picture.path : (row.item.bannerPicture ? row.item.bannerPicture.path : '/assets/images/no-project-image.jpg')"
                       alt="Project Image"/>
              </b-col>
            </b-row>
          </router-link>
        </template>

        <template v-slot:cell(name)="row">
          <div>
            <router-link :id="'project-name-field-' + row.item['id']" class="d-inline align-bottom font-weight-bold"
                         style="font-size:1.25rem;"
                         :to="{ name: 'ProjectShow', params: { id: row.item.urlField }}"
                         :alt="row.item.name" :title="row.item.name + ' citizen science project'">
              {{ ellipsizeCharacters(row.item.name, 70) }}
            </router-link>
            <b-img
              :src="'/assets/images/' + (row.item && row.item.approveContacts ? 'closed' : 'open') + '-access.png'"
              width="20" height="20"
              :alt="((row.item && row.item.approveContacts ? 'Member-Based/Closed' : 'Open') + 'Membership - Requires approval by manager')"
              :title="((row.item && row.item.approveContacts ? 'Member-Based/Closed' : 'Open') + ' Membership - Requires approval by manager')"
              class="closed-opened-status d-inline" style="margin-top:-8px;"/>
            <b-img :src="'/assets/images/' + (row.item && row.item.isPrivate ? 'private' : 'public' )+ '.png'"
                   width="20" height="20"
                   :alt="((row.item && row.item.isPrivate ? 'Private' : 'Public') + ' Data')"
                   :title="((row.item && row.item.isPrivate ? 'Private' : 'Public') + ' Data')"
                   class="private-public-status d-inline ml-1" style="margin-top:-8px;"/>
          </div>

          <div style="height:36px; max-width:550px; line-height:14px;" class="ml-4 mt-1 p-down text-wrap">
            <small>
              {{ ellipsizeCharacters(row.item.description, 170) }}
            </small>
          </div>
        </template>

        <template v-slot:cell(createdAt)="row">
          {{ row.item.createdAt ? displayDate(row.item.createdAt) : "-" }}
        </template>

        <template v-slot:cell(measurementsTotal)="row">
          {{ row.item.measurementsTotal ? row.item.measurementsTotal : 0 }}
        </template>

        <template v-slot:cell(options)="row" class="options-holder">
          <ProjectJoinButton v-bind:project="row.item"></ProjectJoinButton>
        </template>
        <template #table-busy>
          <VueLoader style="height: 200px" text="Loading Projects..."/>
        </template>
      </b-table>

      <div class="">
        <div class="row justify-content-between">
          <div class="my-1 col-md-3">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} projects
          </div>

          <div class="col-md-3 my-1">
            <b-pagination
              v-if="totalItems > 0"
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </div>

          <div class="col-md-3 my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                align="right"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-container>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ProjectJoinButton from '../../assets/scripts/project/projectJoinButton.vue'
import VueLoader from "../layout/Loader.vue";
// import VueLoader from '../../../components/layout/Loader'

export default {
  components: {
    VueLoader,
    ProjectJoinButton,
  },

  data() {
    return {
      // projects: [],
      organizations: [],
      isBusy: false,
      emptyText: "No Projects Available",
      emptyFilteredText: "No Projects Available for Search Criteria",
      originalItemCount: 0,
      // totalItems: 0,
      // currentPage: 1,
      // perPage: 10,
      pageOptions: [10, 25, 50, 100],
      // sortBy: 'measurementsTotal',
      // sortDesc: true,
      // sortDirection: 'desc',
      filterObject: {
        filterText: null,
        filterOrg: null,
      },
      // Boolean indicating if the advanced filter is toggled
      advFilterVisible: false,

      projectsListFields: [
        { key: 'photo', label: '', sortable: false },
        { key: 'name', label: 'Project Name', sortable: true },
        { key: 'createdAt', label: 'Start Date', sortable: true },
        { key: 'measurementsTotal', label: '#Measurements', sortable: true },
        { key: 'options', label: 'Options' },
      ],
      /*{ key: 'mostRecentActivity', label: 'Most Recent Activity', sortable: true },*/
      /*{ key: 'members', label: '#People', sortable: true },*/
    }
  },

  created() {
    // Get the Organizations for the picklist
    // this is done asynchronously
    this.getOrganizations()
  },

  mounted() {
    // Set the initial number of projects
    // this.totalItems = this.allProjects.length
  },

  computed: {
    ...mapGetters({
      isLoading: 'project/list/isLoading',
      allProjects: 'project/list/projects',
      error: 'project/list/error',

      isOrganizationsLoading: 'organization/list/isOrganizationsLoading',
      organizationsList: 'organization/list/organizations',
      featuredHubs: 'organization/list/featuredHubs',
      organizationsError: 'organization/list/organizationsError',

      isProjectARoleManager: 'user/profile/isProjectARoleManager',
      isProjectARoleMember: 'user/profile/isProjectARoleMember',
      isProjectARolePending: 'user/profile/isProjectARolePending',
      isProjectARoleNone: 'user/profile/isProjectARoleNone',

      itemPage: "project/list/page",
      itemPerPage: "project/list/perPage",
      itemTotal: "project/list/total",
      itemFilterText: "project/list/searchFilterText",
      itemFilterOrg: "project/list/searchFilterOrg",
      itemSortBy: "project/list/sortBy",
      itemSortDirection: "project/list/sortDirection",
      itemSortDesc: "project/list/sortDesc",
    }),
    currentPage: {
      get() {
        return this.itemPage
      },
      set(newValue) {
        this.setItemsPage(newValue)
      }
    },
    perPage: {
      get() {
        return this.itemPerPage
      },
      set(newValue) {
        this.setItemsPerPage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.itemTotal
      },
      set(newValue) {
        this.setItemsTotal(newValue)
      }
    },
    filterText:  {
      // Computed value for the search text
      get() {
        return this.itemFilterText
      },
      set(newValue) {
        this.setSearchText(newValue)
      }
    },
    filterOrg:  {
      // Computed value for the hub filter
      get() {
        return this.itemFilterOrg
      },
      set(newValue) {
        this.setSearchOrg(newValue)
      }
    },
    sortBy:  {
      get() {
        return this.itemSortBy
      },
      set(newValue) {
        this.setSortBy(newValue)
      }
    },
    sortDirection:  {
      get() {
        return this.itemSortDirection
      },
      set(newValue) {
        this.setSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.itemSortDesc
      },
      set(newValue) {
        this.setSortDesc(newValue)
      }
    },
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    // Subset the orgArray for only the name
    // this is used to populate the picklist
    organizationsArray() {
      let organizationsIdName = []
      organizationsIdName[0] = { value: null, text: 'Filter by Hub', disabled: true }
      let j=1
      for (let i = 0; i < this.organizationsList.length; i++) {
        organizationsIdName[j] = { value: this.organizationsList[i].url, text: this.organizationsList[i].name }
        j++
      }
      return organizationsIdName
    },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search by name of the project
        // - filter by organization.
        //More filters can be added here
        // - also add an equivalent to the store

        let retVal = ''
        if (this.filterText) {
          retVal = retVal.length === 0 ? 'name=' + this.filterText : retVal + '&name=' + this.filterText
        }
        if (this.filterOrg) {
          retVal = retVal.length === 0 ? 'organizationProjects.organization.url=' + this.filterOrg : retVal + '&organizationProjects.organization.url=' + this.filterOrg
        }
        return retVal
      }
    },
  },

  methods: {
    ...mapActions({
      getOrganizations: 'organization/list/getOrganizations',
      getProjects: 'project/list/get',
      resetProjects: "project/list/refresh",

      setItemsPage: 'project/list/setPage',
      setItemsPerPage: 'project/list/setPerPage',
      setItemsTotal: 'project/list/setTotal',
      setSearchText: 'project/list/setFilterText',
      setSearchOrg: 'project/list/setFilterOrg',
      setSortBy: 'project/list/setSortBy',
      setSortDirection: 'project/list/setSortDirection',
      setSortDesc: 'project/list/setSortDesc',
    }),

    projects(ctx) {
      this.isBusy = true;
      // Project state is set for all requests
      if (!ctx.projectState) {
        ctx.projectState = this.PROJECT_STATE_PUBLISHED
      }
      let urlParams = `${this.urlBuilder(ctx)}`;
      return this.getProjects({ urlParams: urlParams }).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(projects => {
          return {
            ...projects
          };
        });
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (this.totalItems !== filteredItems.length && filteredItems.length > this.perPage) {
        this.totalItems = filteredItems.length
        this.currentPage = 1
      }
    },

    onCreateClick() {
      // Check if user is verified
      if (JSON.parse(localStorage.getItem('sessionData')) !== null) {
        if (!JSON.parse(localStorage.getItem('sessionData')).isVerified) {
          this.$bvModal.show('not-verified-modal')
        } else {
          this.$router.push({ name: 'ProjectCreate' })
        }
      } else {
        this.$router.push({ name: 'Login', query: { redirect: '/projects/create' } });
      }
    },
  },
}
</script>

<style lang="scss" scoped src="../../assets/styles/project/list.scss"></style>
