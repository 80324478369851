import Vue from 'vue'
import VueRouter from 'vue-router';
import VueJWT from 'vuejs-jwt';

// import routes
import appRoutes from './app'
import userRoutes from './user'
import projectRoutes from './project'
import hubRoutes from './hubs'
import datasheetRoutes from './datasheet'
import aboutRoutes from './about'
import missionRoutes from './mission'
import staffRoutes from './staff'
import partnersRoutes from './partners'
import publicationsRoutes from './publications'
import citeRoutes from './cite'
import featuresRoutes from './features'
import solutionsRoutes from './solutions'
import createRoutes from './create'
import buildRoutes from './build'
import measureRoutes from './measure'
import learnRoutes from './learn'
import actRoutes from './act'
import impactRoutes from './impact'
import servicesRoutes from './services'
import developersRoutes from './developers'
import apisRoutes from './apis'
import widgetsRoutes from './widgets'
import appsRoutes from './apps'
import architectureRoutes from './architecture'

import connectRoutes from './connect'
import contactRoutes from './contact'
import privacyRoutes from './privacy'
import termsRoutes from './terms'
import donateRoutes from './donate'
import newsletterRoutes from './newsletter'
import webinarsRoutes from './webinars'
import surveysRoutes from './surveys'

import observationRoutes from './observation'
import organizationRoutes from './organization';
import locationRoutes from './location'

import notFoundRoutes from './notFound'
import unauthorizedAccessRoutes from './unauthorizedAccess'

// used to get the user data from API
import { getProjectIdByNameWithoutCommit } from '../store/modules/project/show/actions.js'
import {appRootUrl} from "../main";

Vue.use(VueRouter)
Vue.use(VueJWT, { keyName: 'tokenBearer' })

// Add routes to VueRouter
const router = new VueRouter({
    mode: 'history',
    routes: [
      ...appRoutes,
      ...userRoutes,
      ...projectRoutes,
      ...hubRoutes,
      ...datasheetRoutes,
      ...aboutRoutes,
      ...missionRoutes,
      ...staffRoutes,
      ...partnersRoutes,
      ...publicationsRoutes,
      ...citeRoutes,
      ...featuresRoutes,
      ...solutionsRoutes,
      ...servicesRoutes,
      ...createRoutes,
      ...buildRoutes,
      ...measureRoutes,
      ...learnRoutes,
      ...actRoutes,
      ...impactRoutes,
      ...developersRoutes,
      ...apisRoutes,
      ...widgetsRoutes,
      ...appsRoutes,
      ...architectureRoutes,
      ...privacyRoutes,
      ...termsRoutes,
      ...connectRoutes,
      ...contactRoutes,
      ...donateRoutes,
      ...newsletterRoutes,
      ...webinarsRoutes,
      ...surveysRoutes,
      ...observationRoutes,
      ...organizationRoutes,
      ...locationRoutes,
      ...notFoundRoutes,
      ...unauthorizedAccessRoutes
    ],
    scrollBehavior(to, from, savedPosition) {
      // using y=2 for firefox compatibility
      return { x: 0, y: 2 }
    }
  })

  /*
  * This function navigates to the appropriate route and is called  from router.beforeEach function
  */
  let redirect = ( to, toHome, requiresAuth, next ) => {
    if (!to.matched.length) {
      next({name: 'NotFound'})
    } else if (requiresAuth && Vue.$jwt.hasToken() == null) {
      next({
        name: 'Login',
        query: { redirect: to.fullPath }
      })
    } else if (toHome && Vue.$jwt.hasToken()) {
      next({name: 'HomePage'})
    } else {
      next()
    }

  };

  /*
  * This function will check session related info and based on the
  * tokens this function would call redirect function
  * */
  let checkTokenBeforeRedirect = ( to, toHome, requiresAuth, next ) => {

    // TODO: what is this if statement checking for?
    // if a user has a token AND the session data is blank, call the API to get the session
    // if the API call does not succeed, then redirect to login & clear session
    // otherwise continue on your way
    if (Vue.$jwt.hasToken() !== null && localStorage.getItem('sessionData') === null || localStorage.getItem('sessionData') === '') {
      store.dispatch('user/profile/fetchSession').then((response) => {
        if (response === false) {
          localStorage.removeItem('sessionData');
          localStorage.removeItem('tokenBearer');
          localStorage.removeItem('tokenRefresh');

          next({
            name: 'Login',
            query: {redirect: to.fullPath}
          })
        } else {
          redirect(to, toHome, requiresAuth, next)
        }
      })
    } else {

      // if (JSON.parse(localStorage.getItem('sessionData')) !== null) {
      //
      //   // If user is not verified, redirect to Verify Account page
      //   // - Prevent recursion by checking if already redirecting to Verify Account Page
      //   // - Allow exception for logout & Activate pages
      //   const verifyStatus = JSON.parse(localStorage.getItem('sessionData')).isVerified
      //   console.log(to.name, verifyStatus)
      //   if ( verifyStatus === false && (to.name === 'ProjectCreate' && to.name !== 'VerifyEmail') ) {
      //     next({
      //       name: 'VerifyEmail',
      //       query: {redirect: to.fullPath}
      //     })
      //   }
      // }
      redirect(to, toHome, requiresAuth, next)

    }
  };

  /**
   * Checks if the user can access the route
   * - redirect to Login page otherwise
   */
  router.beforeEach((to, from, next) => {

    // Redirect from https://www.citsci to https://citsci
    let url = appRootUrl
    if (url.indexOf('www.') > -1) {
      // console.log('redirect to non-www', url.toLowerCase().indexOf('www.'), url.replace(/www\./, ''))
      // replace current url with non-www version
      window.location.href = url.replace(/www\./, '');
      return;
    }

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const toHome = to.matched.some(record => record.meta.toHomeIfAuthenticated)

    // Handle the project id => project url transformation
    if (to.name && to.name.toLowerCase().indexOf('project') !== -1 && to.params && to.params.id) {
      getProjectIdByNameWithoutCommit(to.params.id).then((data) => {
        if (data) {
          checkTokenBeforeRedirect(to, toHome, requiresAuth, next);
        } else {
          checkTokenBeforeRedirect(to, toHome, requiresAuth, next);
        }
      });
    // } else if (to.name.toLowerCase() === 'verifyemail') {
    //   // Redirect to Verify Account page, prevent recursion
    //   next()sc
    } else {
      checkTokenBeforeRedirect(to, toHome, requiresAuth, next);
    }
  });

  export default router;
