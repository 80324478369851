var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('h1',[_vm._v("Hubs")]),_vm._v(" "),_c('div',{staticClass:"container p-0",attrs:{"data-aos":"fade-up"}},[_c('div',{staticClass:"w-75 mx-auto"},[_c('p',[_vm._v("Do you or your organization run many participatory science projects? Need a centralized\n        dashboard to share out all your projects and key indicators? Look no further than CitSci\n        Hubs. Interested? "),_c('b-link',{attrs:{"to":"contact"}},[_vm._v("Reach out")]),_vm._v(" and we'll be happy to discuss\n        available options, share more details, and get you started today.\n      ")],1),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('div',{staticClass:"container p-0",attrs:{"data-aos":"fade-up"}},[_c('h2',[_vm._v("Featured")]),_vm._v(" "),(!_vm.isOrganizationsLoading)?_c('div',{staticClass:"row d-flex align-items-stretch"},_vm._l((_vm.featuredHubs),function(featuredHub){return _c('div',{key:featuredHub.id,staticClass:"col-md-2 mb-2 mx-0"},[_c('div',{staticClass:"card p-0 mx-0"},[(featuredHub.banner)?_c('div',{style:({
            'background-image': 'url(' + featuredHub.banner.path + ')',
            'height': '120px',
            'background-color': '#cccccc',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover'
          })}):_c('div',{staticStyle:{"height":"120px","background-color":"grey"}},[_vm._v(" ")]),_vm._v(" "),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'OrganizationShow', params: { name: featuredHub.url }},"alt":featuredHub.name + ' Hub',"title":featuredHub.name + ' Hub'}}),_vm._v(" "),_c('div',{staticClass:"card-footer pl-2 pr-0",staticStyle:{"background-color":"#F5F4E8"}},[_c('h5',{staticClass:"d-none d-md-block"},[_vm._v(_vm._s(_vm.ellipsizeCharacters(featuredHub.name, 16)))]),_vm._v(" "),_c('h5',{staticClass:"d-md-none"},[_vm._v(_vm._s(featuredHub.name))])])],1)])}),0):_c('div',{},[_c('i',{staticClass:"fa fa-spin fa-spinner fa-3x mr-3"}),_vm._v(" Loading Hubs...\n    ")])]),_vm._v(" "),_c('div',{staticClass:"container p-0",attrs:{"data-aos":"fade-up"}},[_c('h2',[_vm._v("All")]),_vm._v(" "),(!_vm.isOrganizationsLoading)?_c('div',{staticClass:"row d-flex align-items-stretch"},_vm._l((_vm.organizationsList),function(organization){return _c('div',{key:organization.url,staticClass:"col-md-2 mb-4 mx-0"},[_c('div',{staticClass:"card p-0 mx-0"},[(organization.banner)?_c('div',{style:({
            'background-image': 'url(' + organization.banner.path + ')',
            'height': '120px',
            'background-color': '#cccccc',
            'background-position': 'center',
            'background-repeat': 'no-repeat',
            'background-size': 'cover'
          })}):_c('div',{staticStyle:{"height":"120px","background-color":"grey"}},[_vm._v(" ")]),_vm._v(" "),_c('router-link',{staticClass:"stretched-link",attrs:{"to":{ name: 'OrganizationShow', params: { name: organization.url }},"alt":organization.name + ' Hub',"title":organization.name + ' Hub'}}),_vm._v(" "),_c('div',{staticClass:"card-footer pl-2 pr-0",staticStyle:{"background-color":"#F5F4E8"}},[_c('h5',{staticClass:"d-none d-md-block"},[_vm._v(_vm._s(_vm.ellipsizeCharacters(organization.name, 16)))]),_vm._v(" "),_c('h5',{staticClass:"d-md-none"},[_vm._v(_vm._s(organization.name))])])],1)])}),0):_c('div',{},[_c('i',{staticClass:"fa fa-spin fa-spinner fa-3x mr-3"}),_vm._v(" Loading Hubs...\n    ")])]),_vm._v(" "),_c('div',{staticClass:"container p-0 mb-5 mt-5"},[_c('p',[_vm._v("Please "),_c('b-link',{attrs:{"to":"contact"}},[_vm._v("let us know")]),_vm._v(" your ideas about how we can improve these hubs\n      as we continue to build out new capabilities.\n    ")],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-none d-lg-block"},[_c('ul',{staticClass:"list-group list-group-horizontal mb-5 mt-5"},[_c('li',{staticClass:"list-group-item flex-fill rounded-pill mr-4 text-center p-down text-grey bg-light"},[_c('i',{staticClass:"mr-2 fa fa-lightbulb",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Simple\n          ")]),_vm._v(" "),_c('li',{staticClass:"list-group-item flex-fill rounded-pill mr-4 text-center p-down text-grey bg-light"},[_c('i',{staticClass:"mr-2 fa fa-project-diagram",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Organized\n          ")]),_vm._v(" "),_c('li',{staticClass:"list-group-item flex-fill rounded-pill text-center p-down text-grey bg-light"},[_c('i',{staticClass:"mr-2 fa fa-pencil",attrs:{"aria-hidden":"true"}}),_vm._v("\n            Configurable\n          ")])])])}]
export { render, staticRenderFns }